<template>
  <div class="jtn-video">
    <iframe
      v-if="isIframeVideo"
      ref="videoFrame"
      :src="videoUrl"
      height="100%"
      width="100%"
      frameborder="0"
      allow="autoplay; encrypted-media"
      allowfullscreen
    />
  </div>
</template>

<script>
export default {
  name: 'JtnVideo',
  props: {
    videoUrl: {
      type: String,
      default: ''
    },
    sourceName: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isPlayIframeVideo: true
    };
  },
  computed: {
    isIframeVideo() {
      return this.videoUrl !== '' && this.isPlayIframeVideo;
    }
  },
  mounted() {
    // костыль для отслеживания клика по iframe
    const listener = window.addEventListener('blur', () => {
      if (document.activeElement === this.$refs.videoFrame) {
          this.$emit('onClickedVideo');
      }
      window.removeEventListener('blur', listener);
    });
  },
  methods: {
    stopVideo() {
      // хак для остановки видео в iframe
      this.isPlayIframeVideo = false;
      this.$nextTick(() => {
        this.isPlayIframeVideo = true;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.jtn-video {
  display: block;
  width: 100%;
  height: 100%;
}
</style>
