<template>
  <div class="central-column">
    <window-block
      v-if="windowBlockData && windowBlockData.length > 0"
    />

    <template v-for="(blockItem, index) of centralColumnBlocks">
      <component
        v-if="isAdvBlock(blockItem.type)"
        v-bind="bindAdvComponent"
        :key="blockItem.type + index"
        :adv="blockItem"
        :index="index"
      />

      <feedback-block
        v-if="blockItem.type === 'editorial_office'"
        :key="blockItem.type + index"
        :class="[getClasses(blockItem.settings.types, 'hidden'), 'block']"
      />

      <auto-block
        v-if="blockItem.type === 'avito_auto'"
        :key="blockItem.type + index"
        :class="[getClasses(blockItem.settings.types, 'hidden'), 'block']"
        :devices="getDevices(blockItem)"
        position="center-column"
        type="Avito"
      />

      <widget-photo-day
        v-if="blockItem.type === 'photoday'"
        :key="blockItem.type + index"
        :class="[getClasses(blockItem.settings.types, 'hidden'), 'block']"
      />

      <widget-video-day
        v-if="blockItem.type === 'videoday'"
        :key="blockItem.type + index"
        :class="[getClasses(blockItem.settings.types, 'hidden'), 'block']"
      />

      <template v-if="blockItem.type === 'top_news'">
        <top-records-block
          :key="blockItem.type + index"
          :wideMode="wideModeTopRecord"
          :class="[getClasses(blockItem.settings.types, 'hidden'), 'block']"
        />
      </template>

      <widget-immortal-rotation
        v-if="blockItem.type === 'everlastingnews'"
        :key="blockItem.type + index"
        :class="[getClasses(blockItem.settings.types, 'hidden'), 'block']"
      />

      <widget-network-content
        v-if="blockItem.type === 'network_content'"
        :key="blockItem.type + index"
        :class="[getClasses(blockItem.settings.types, 'hidden'), 'block']"
      />

      <template v-if="blockItem.type === 'forum'">
        <forums-block
          :key="blockItem.type + index"
          :class="[getClasses(blockItem.settings.types, 'hidden'), 'block']"
        />
      </template>
    </template>
  </div>
</template>
<script>
import { StoreMixin } from '../../../shared/mixins';

import WindowBlock from '../../../shared/components/window-block/window-block';
import { Adv, AdvCreative } from '../../../shared/components/_adv';
import { FeedbackBlock } from '../../../shared/components/feedback-block';
import { ForumsBlock } from '../../../shared/components/forums-block';
import { AutoBlock } from '../../../shared/components/auto-block';
import { TopRecordsBlock } from '../../../shared/components/top-records-block/';
import WidgetPhotoDay from '../../../shared/widgets/widget-photo-day';
import WidgetVideoDay from '../../../shared/widgets/widget-video-day';
import { WidgetImmortalRotation } from '../../../shared/widgets/widget-immortal-rotation';
import { WidgetNetworkContent } from '../../../shared/widgets/widget-network-content';

export default {
  name: 'CentralColumn',
  components: {
    WindowBlock,
    Adv,
    AdvCreative,
    FeedbackBlock,
    ForumsBlock,
    AutoBlock,
    TopRecordsBlock,
    WidgetPhotoDay,
    WidgetVideoDay,
    WidgetImmortalRotation,
    WidgetNetworkContent
  },
  mixins: [StoreMixin],
  computed: {
    dataFetching() {
      return this.store.dataFetching;
    },
    centralColumnBlocks() {
      return this.dataFetching ? [] : this.store.layoutModule.centralColumn;
    },
    windowBlockData() {
      return this.store.layoutModule.windowBlock.data;
    },
    isDesktop() {
      return this.store.deviceInfo.isDesktop;
    },
    wideModeTopRecord() {
      return this.isDesktop;
    },
    isHoldoutTest() {
      return this.store.layoutModule.isHoldoutTest;
    },
    isScroogeTest() {
      return this.store.layoutModule.isScroogeTest;
    },
    bindAdvComponent() {
      return {
        is: this.isScroogeTest ? 'AdvCreative' : 'Adv'
      };
    }
  },
  methods: {
    getDevices(block) {
      return Object
        .entries(block.settings.types)
        .reduce((acc, [key, value]) => {
          if (value) { acc.push(key) }
          return acc
        }, [])
    },
    advKey(item) {
      return item.settings.placeId;
    },
    isAdvBlock(type) {
      return type === 'adv' || type === 'mobile_record_inread_advert' || (type === 'flyroll_advert' && !this.isHoldoutTest);
    },
    getClasses(classes, blockType) {
      if (classes !== undefined) {
        return {
          ...classes,
          [blockType]: true
        };
      }
      return null;
    }
  }
};
</script>
<style lang="scss" scoped>
@import 'styles';

.central-column {
  width: 100%;
  box-sizing: border-box;

  .hidden {
    display: none;
  }

  .block {
    margin-top: 12px;
    margin-bottom: 12px;

    @include on-min-resize($tablet) {
      margin-top: 32px;
      margin-bottom: 32px;
    }

    &:not(.hidden) {
      display: block;
    }
  }
}
</style>
