import {
  getResponsiveImageData,
  ImageAspectRatio,
  ImageDecoding,
  ImageLoading,
  ImageSourceVM,
  ResponsiveImageValues,
} from '@jtnews/shared/images';

import { Image } from '../../domain';

type PhotosDayData = Image & {
  mainImage: ImageData;
  previewImage: ImageData;
  bigImage: ImageData;
};

type ImageData = {
  src: string;
  hasCommercialLabel: boolean;
  sources: ImageSourceVM[];
  decoding?: ImageDecoding;
  loading?: ImageLoading;
};

const mainValues = [
  {
    breakpoint: 375,
    width: 375,
  },
  {
    breakpoint: 599,
    width: 599,
  },
  {
    breakpoint: 899,
    width: 795,
  },
  {
    breakpoint: 1279,
    width: 911,
  },
  {
    breakpoint: 0,
    width: 666,
    noMedia: true,
  },
];

const previewValues = [
  {
    breakpoint: 0,
    width: 188,
    noMedia: true,
  },
];

const bigValues = [
  {
    breakpoint: 375,
    width: 375,
  },
  {
    breakpoint: 599,
    width: 599,
  },
  {
    breakpoint: 899,
    width: 851,
  },
  {
    breakpoint: 1279,
    width: 1231,
  },
  {
    breakpoint: 0,
    width: 1032,
    noMedia: true,
  },
];

const getImageData = (input: {
  url: string;
  width: number;
  values: ResponsiveImageValues[];
  aspectRatio: ImageAspectRatio;
  isMain: boolean;
  isCrop: boolean;
  hasHeight: boolean;
  hasCommercialLabel: boolean;
}) => {
  const {
    url,
    width,
    values,
    aspectRatio,
    isMain,
    isCrop,
    hasHeight,
    hasCommercialLabel,
  } = input;
  const info = getResponsiveImageData({
    url,
    width,
    values,
    hasCommercialLabel,
    isPreloadImage: isMain,
    isLazy: !isMain,
    aspectRatio,
    isCrop,
    hasHeight,
  });

  return {
    src: info.data.src,
    sources: info.data.sources,
    decoding: info.data.decoding,
    loading: info.data.loading,
    hasCommercialLabel: info.data.hasCommercialLabel,
  };
};

export const createPhotoDay = (photo: Image): PhotosDayData => {
  return {
    ...photo,
    bigImage: getImageData({
      url: photo.url,
      width: photo.width,
      values: bigValues,
      aspectRatio: ImageAspectRatio.Classic,
      isMain: false,
      isCrop: false,
      hasHeight: false,
      hasCommercialLabel: photo.hasCommercialLabel,
    }),
    previewImage: getImageData({
      url: photo.url,
      width: photo.width,
      values: previewValues,
      aspectRatio: ImageAspectRatio.Wide,
      isMain: false,
      isCrop: true,
      hasHeight: true,
      hasCommercialLabel: photo.hasCommercialLabel,
    }),
    mainImage: getImageData({
      url: photo.url,
      width: photo.width,
      values: mainValues,
      aspectRatio: ImageAspectRatio.Classic,
      isMain: true,
      isCrop: true,
      hasHeight: true,
      hasCommercialLabel: photo.hasCommercialLabel,
    }),
  };
};
