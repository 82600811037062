<template>
  <div
    v-observe-visibility="obsVisibilityOptions"
    v-show="!hideWidget"
    :class="['widgetPhotoDay', 'photos']"
  >
    <widget-gallery-slider
      v-observe-visibility="obsHalfOptions"
      v-if="renderWidgetSliderWrapper"
      :title="title"
      :title-link="photosPageUrl"
      :has-thumbs="!isMobile"
      :has-push-from-last-slide="true"
      :show-loader="!dataLoaded"
      :image-ratio="imageRatio"
      nav-buttons-type="over_photo"
      theme="light"
      :media-content-class-name="mediaContentClassName"
      :slider-content-class-name="sliderContentClassName"
      @slideChanged="slideChanged"
      @swiped="slideSwiped($event, 'Inscreen')"
      @navBtnClicked="navBtnClicked($event, 'Inscreen')"
      @titleClicked="sendReachGoalsForTitleClicked"
    >
      <template slot="slides">
        <template v-for="item of items">
          <li :key="item.id" class="sliderItem">
            <div :class="sliderContentClassName">
              <div class="sliderItemHeaderContainer">
                <div>
                  <jtn-ui-typography
                    class="sliderItemHeader"
                    tag="h3"
                    type="font-center-title"
                    link-type="link-dark-type-2"
                  >
                    <a
                      v-if="item.url"
                      :href="item.url"
                      :title="entityDecode(item.header)"
                      :target="item.url.indexOf('http') < 0 ? '_self' : '_blank'"
                      @click="
                          navigateFromHeader(item.url, 'Переход на материал', 'Заголовок')
                        "
                      v-html="item.header"
                    />

                    <span v-else v-html="item.header" />
                  </jtn-ui-typography>

                  <jtn-ui-typography
                    v-if="item.url"
                    class="sliderItemHeaderLink"
                    tag="a"
                    type="font-photoday-type-1"
                    link-type="link-blue"
                    :href="item.url"
                    :target="item.url.indexOf('http') < 0 ? '_self' : '_blank'"
                    @click="navigateFromHeader(item.url, 'Смотреть материал')"
                  >
                    Смотреть материал
                  </jtn-ui-typography>
                </div>

                <div class="sliderItemInfo">
                  <div class="sliderItemInfoText">
                    <jtn-ui-typography
                      v-if="item.image.author"
                      tag="span"
                      type="font-photoday-type-1"
                      class="sliderItemInfoItem author"
                      v-html="item.image.author"
                    />
                    <jtn-ui-typography
                      tag="span"
                      type="font-photoday-type-1"
                      class="sliderItemInfoItem date"
                    >
                      {{ dateFormat(item.publishAt) }}
                    </jtn-ui-typography>
                  </div>

                  <jtn-ui-share
                    :btn-size="36"
                    :is-popup="true"
                    :popup-position="isMobile ? 'top-right' : 'bottom-right'"
                    :is-widget-share="true"
                    class="sliderItemInfoBtn"
                    @shared="sharedPhoto($event, item.id, item.header, item.url)"
                  />
                </div>
              </div>
              <div :class="mediaContentClassName">
                <JtnUiImage
                  :aspectRatio="imageRatio"
                  :src="item.image.mainImage.src"
                  :sources="item.image.mainImage.sources"
                  :loading="item.image.mainImage.loading"
                  :hasCommercialLabel="item.image.mainImage.hasCommercialLabel"
                  :decoding="item.image.mainImage.decoding"
                  @click="openImageViewer(item)"
                />
              </div>
            </div>
          </li>
        </template>
      </template>

      <template slot="thumbs">
        <template v-for="item of items">
          <div v-if="!isMobile" :key="item.id" class="thumbItem" @click="sendThumbsCTR()">
            <JtnUiImage
              :aspectRatio="previewRatio"
              :src="item.image.previewImage.src"
              :hasCommercialLabel="item.image.previewImage.hasCommercialLabel"
              :sources="item.image.previewImage.sources"
              :loading="item.image.previewImage.loading"
              :decoding="item.image.previewImage.decoding"
            />
          </div>
        </template>
      </template>
    </widget-gallery-slider>
    <widget-image-viewer
      v-if="isImageViewerRendered"
      v-show="isImageViewerVisible"
      :iv-id="ivId"
      :items="items"
      :adv="imageViewerAdv"
      :max-items-length="maxItemsLength"
      title="Фото дня"
      @navBtnClicked="navBtnClicked($event, 'Fullscreen')"
      @swiped="slideSwiped($event, 'Fullscreen')"
      @shareImage="sendReachGoalsForSharedImage({ ...$event, isFullscreen: true })"
      @headerClicked="
          navigateFromHeader($event, 'Переход на материал', 'Заголовок (фулскрин)')
        "
      @closedImageViewer="sendReachGoalsForClosedImageViewer($event)"
    />
  </div>
</template>

<script>
import { Subject } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';
import { dateFormat } from '@portal/utils/util-date';

import { DEFAULT_DATE_FORMAT } from '@jtnews/shared/data';

import { createPhotoDay } from '@jtnews/shared/news';
import {
  PHOTO_AND_VIDEO_PAGE_SIZES,
  PHOTO_WIDGET_VISIBLE_PAGES_COUNT
} from '@jtnews/shared/data';
import { smoothScroll } from '@jtnews/shared/smooth-scroll';
import { JtnUiTypography, JtnUiShare } from '@jtnews/jtn-ui';
import { JtnUiImage, AspectRatio } from '@jtnews/jtn-ui';

import changeImagePlaceholder from '../../utils/changeImagePlaceholder';
import generateImageUrl from '../../utils/generateImageUrl';

import { StoreMixin } from '../../mixins';
import EntityDecoderMixin from '../../mixins/entity-decoder.mixin';
import { BaseBlockFunctionalityMixin } from '../../mixins/base-block-functionality.mixin';

import { WidgetGallerySlider } from '../../widgets/widget-gallery-slider';

const WidgetImageViewer = () =>
  import(/* webpackChunkName: "widget-image-viewer" */ '../widget-image-viewer');

const PAGESIZE = PHOTO_AND_VIDEO_PAGE_SIZES * PHOTO_WIDGET_VISIBLE_PAGES_COUNT;

const IMG_PREVIEW_WIDTH = 188;

export default {
  name: 'WidgetPhotoDay',
  components: {
    JtnUiTypography,
    WidgetGallerySlider,
    WidgetImageViewer,
    JtnUiImage,
    JtnUiShare
  },
  mixins: [EntityDecoderMixin, StoreMixin, BaseBlockFunctionalityMixin],
  data() {
    return {
      renderWidgetSliderWrapper: false,
      hideWidget: false,
      dataLoaded: false,
      sharePhoto: null,
      page: 1,
      maxItemsLength: PAGESIZE,
      items: [],
      formatDate: DEFAULT_DATE_FORMAT,
      imageRatio: AspectRatio.Classic,
      previewRatio: AspectRatio.Wide,
      isImageViewerVisible: false,
      isImageViewerRendered: false,
      imageViewerAdv: {},
      ivId: 'iv-widget-photo-day',
      destroy: new Subject(),
      sliderContentClassName: 'sliderItemContent',
      mediaContentClassName: 'mediaContent',
      previewImgWidth: IMG_PREVIEW_WIDTH
    };
  },
  computed: {
    regionId() {
      return this.store.regionId;
    },
    newsApiClient() {
      return this.store.newsApiClient;
    },
    isMobile() {
      return this.store.deviceInfo.isMobile && !this.store.deviceInfo.isTablet;
    },
    params() {
      if (this.blockData && this.blockData.params) {
        return this.blockData.params;
      }

      return {};
    },
    title() {
      return this.params.title ? this.params.title : '';
    },
    photosPageUrl() {
      return this.params.path ? this.params.path : '';
    }
  },
  beforeMount() {
    if (this.$route.query.share !== undefined) {
      this.obsVisibilityOptions = undefined;
      this.getPhotoById(this.$route.query.share);
    }
  },
  mounted() {
    this.store.layoutModule.imageViewerService.state$
      .pipe(
        takeUntil(this.destroy),
        filter(state => state.id === this.ivId)
      )
      .subscribe(state => {
        if (state.isOpen) {
          if (this.isImageViewerRendered) {
            this.store.layoutModule.imageViewerService.changeScrollState({
              value: 'disable'
            });
          }
          this.isImageViewerRendered = true;
          this.isImageViewerVisible = true;
        } else {
          this.isImageViewerVisible = false;
        }
      });
  },
  beforeDestroy() {
    this.destroy.next();
    this.destroy.unsubscribe();
  },
  methods: {
    onVisibleBlock() {
      const firstSlide = this.items[0];

      this.sendNewReachGoal('Просмотр', 'viewDayPhotoBlock');
      this.sendNewFormatReachGoal({
        goalName: 'ViewPhotoday',
        actionType: 'Просмотр',
        prop1:
          firstSlide.url !== '' ? this.getRecordIdFromUrl(firstSlide.url) : firstSlide.id
      });
    },
    async componentShown() {
      await this.fetch();
    },
    async fetch() {
      try {
        const dataKey = 'photos_of_the_day.data';
        const advKey = 'photos_of_the_day_adv';
        const res = await this.newsApiClient.getPhotosOfTheDayBlock({
          regionId: this.regionId,
          pagesize: PAGESIZE,
          page: this.page
        });

        this.blockData = res.data[dataKey];

        const newImages = this.checkSharingPhoto(this.blockData.data)
          .map(data => ({
            ...data,
            image: createPhotoDay(data.image)
          }));

        this.items = [...this.items, ...newImages];

        if (this.items && this.items.length > 0) {
          this.dataLoaded = true;
          this.renderWidgetSliderWrapper = true;
        } else {
          this.hideWidget = true;
        }
        this.imageViewerAdv = res.data[advKey];
      } catch (err) {
        this.hideWidget = true;
        console.log(err);
        throw err;
      }
    },
    async getPhotoById(recordId) {
      try {
        // TODO: переделать на newsApiClient.getPhotosOfTheDayBlock,
        // когда там можно будет получать расшаренное фото
        const photo = await this.newsApiClient.getPhotoOfTheDayById(
          this.regionId,
          recordId
        );
        this.sharePhoto = { ...photo, image: createPhotoDay(photo.image) };
        this.items = [this.sharePhoto];
        this.fetch();
      } catch (err) {
        console.log(err);
      }
    },
    checkSharingPhoto(items) {
      if (this.sharePhoto) {
        const photos = items.filter(photo => photo.id !== this.sharePhoto.id);

        setTimeout(this.scrollToWidget, 1000);

        return photos;
      }

      return items;
    },
    sharedPhoto(event, id, header, url) {
      this.sendReachGoalsForSharedImage({
        socialTarget: event,
        id,
        url
      });

      this.store.sharingModule.sharePhotoDay({
        socialTarget: event,
        id: id.toString(),
        title: this.entityDecode(header),
        utmParams: this.store.sharingModule.getUtmParams(
          'sharephotodaymain',
          this.getUtmCampaign(url),
          `${this.regionId}`
        )
      });
    },
    changeImagePlaceholder(image) {
      return changeImagePlaceholder(image);
    },
    generateImageUrl(image, width, height) {
      return generateImageUrl(image, true, width, height);
    },
    dateFormat(dateStr) {
      try {
        return dateFormat(dateStr, this.formatDate);
      } catch (e) {
        return '';
      }
    },
    scrollToWidget() {
      if (this.$el) {
        setTimeout(() => {
          const clientRect = this.$el.getBoundingClientRect();
          const top = clientRect.top + window.pageYOffset;

          smoothScroll(top, 900);
        });
      }
    },
    slideChanged($event) {
      this.checkPushToPhotoPage($event);
    },
    slideSwiped(event, screenView) {
      if (this.store.deviceInfo.isMobile || this.store.deviceInfo.isTablet) {
        this.sendNewFormatReachGoal({
          goalName: 'ScrollPhotoday',
          actionType: 'Скролл',
          prop1: event === 'prev' ? 'Влево' : 'Вправо',
          prop2: screenView
        });
      }
    },
    checkPushToPhotoPage(event) {
      if (event.current === this.items.length) {
        location.href = `/photos/?page=${PHOTO_WIDGET_VISIBLE_PAGES_COUNT + 1}`;
      }
    },
    navBtnClicked(event, screenView) {
      const valueName = event === 'prev' ? 'стрелка влево' : 'стрелка вправо';

      this.sendNewReachGoal(valueName, 'clickDayPhotoBlock');
      this.sendNewFormatReachGoal({
        goalName: 'ScrollPhotoday',
        actionType: 'Скролл',
        prop1: event === 'prev' ? 'Влево' : 'Вправо',
        prop2: screenView
      });
    },
    navigateFromHeader(url, valueName, valueNameNewFormat) {
      this.sendNewReachGoal(valueName, 'clickDayPhotoBlock');
      this.sendNewFormatReachGoal({
        goalName: 'ClickPhotoday',
        actionType: 'Клик',
        prop1: this.getRecordIdFromUrl(url),
        prop2: valueNameNewFormat ? valueNameNewFormat : valueName
      });
    },
    sendThumbsCTR() {
      this.sendNewReachGoal('Листалка (маленькие фото)', 'clickDayPhotoBlock');
      this.sendNewFormatReachGoal({
        goalName: 'ScrollPhotoday',
        actionType: 'Скролл',
        prop1: 'Превью',
        prop2: 'Inscreen'
      });
    },
    openImageViewer(item) {
      this.sendNewReachGoal('Выбранное фото (открыть фенсибокс)', 'clickDayPhotoBlock');
      this.sendNewFormatReachGoal({
        goalName: 'ClickPhotoday',
        actionType: 'Клик',
        prop1: item.url !== '' ? this.getRecordIdFromUrl(item.url) : item.id,
        prop2: 'Открыл Фулскрин'
      });
      this.store.layoutModule.imageViewerService.changeState({
        id: this.ivId,
        isOpen: true,
        imageId: item.image.id
      });
    },
    sendReachGoalsForClosedImageViewer(event) {
      this.sendNewFormatReachGoal({
        goalName: 'ClickPhotoday',
        actionType: 'Клик',
        prop1: event.url !== '' ? this.getRecordIdFromUrl(event.url) : event.id,
        prop2: 'Закрыл Фулскрин'
      });
    },
    sendReachGoalsForTitleClicked() {
      this.sendNewReachGoal('Фото дня (заголовок)', 'clickDayPhotoBlock');
      this.sendNewFormatReachGoal({
        goalName: 'ClickPhotoday',
        actionType: 'Клик',
        prop1: this.photosPageUrl,
        prop2: 'Фото дня'
      });
    },
    sendReachGoalsForSharedImage({ socialTarget, id, url, isFullscreen = false }) {
      this.sendNewReachGoal({ 'Поделиться': socialTarget }, 'clickDayPhotoBlock');
      this.sendNewFormatReachGoal({
        goalName: 'ClickPhotoday',
        actionType: 'Клик',
        prop1: url !== '' ? this.getRecordIdFromUrl(url) : id,
        prop2: `Поделиться ${socialTarget}${isFullscreen ? ' (фулскрин)' : ''}`
      });
    },
    sendNewReachGoal(valueName, goalName) {
      const blockType = 'Центральная колонка';
      const fieldType = 'Фото дня (блок)';

      this.store.analyticsModule.sendNewReachGoal({
        blockType,
        fieldType,
        valueName,
        goalName,
        willDeprecate: true
      });
    },
    sendNewFormatReachGoal({ goalName, actionType, prop1, prop2 }) {
      this.store.analyticsModule.sendNewFormatReachGoal({
        blockType: 'Фото дня',
        goalName,
        actionType,
        prop1,
        prop2
      });
    },
    // TODO избавиться от метода, когда бек добавит id материала в данные REGIONNEWS-31863
    getRecordIdFromUrl(url) {
      const id = url.match(/\d{8}/gi);
      return id ? id[0] : url;
    },
    getUtmCampaign(url) {
      const id = url.match(/\d{8}/gi);
      return id ? id[0] : 'none';
    }
  }
};
</script>

<style lang="scss" scoped>
@import 'styles';
@import '../../../styles/swiper-slider';

.widgetPhotoDay {
  display: flex;

  margin: 0 $central-column-mobile-padding-negative;

  box-sizing: border-box;
  min-height: 460px;

  .thumbItem > div {
    z-index: -1;
  }

  @include on-min-resize($tablet) {
    min-height: 441px;
    margin: 0;
  }
  @include on-min-resize($laptop) {
    min-height: 480px;
  }
  @include on-min-resize($desktop) {
    min-height: 650px;
  }
}

.sliderItemHeaderContainer {
  padding: 0 $central-column-mobile-padding;

  @include on-min-resize($tablet) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    height: 100%;
    margin: 0 0 8px;
    padding: 16px 0 0;

    border-top: 1px solid $color-surface-100;
  }
}

.sliderItemHeader {
  display: inline;

  margin: 0 12px 8px 0;
}

.sliderItemHeaderLink {
  display: inline-block;

  padding: 4px 0 0;

  @include on-res($mobile) {
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
  }
}

.sliderItemInfo {
  align-items: flex-end;

  @include on-min-resize($tablet) {
    align-items: center;

    height: 20px;
  }
}

.sliderItemInfoBtn {
  @include on-res($mobile) {
    margin-top: -8px;
  }
}

.mediaContent {
  cursor: pointer;
}
</style>
