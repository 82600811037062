import { componentFactoryOf } from 'vue-tsx-support';

import { injectStylesMixin } from '@jtnews/shared';

import styles from './widget-traffic.styles.scss?module';

type Traffic = {
  level: number;
  link: string;
};

type Events = {
  onTrafficWidgetClicked: void;
};

export default componentFactoryOf<Events>()
  .mixin(injectStylesMixin(styles))
  .create({
    name: 'WidgetTrafficRedesign',
    props: {
      widgetData: {
        type: Object as () => Traffic,
        default: null
      }
    },
    computed: {
      statusColorClass(): string {
        if (this.widgetData.level >= 4 && this.widgetData.level <= 6) {
          return 'yellow';
        }

        if (this.widgetData.level >= 7) {
          return 'red';
        }

        return '';
      },
      textSizeClass(): string {
        if (this.widgetData.level === 10) {
          return 'small';
        }

        return '';
      }
    },
    methods: {
      widgetClickHandler(): void {
        this.$emit('trafficWidgetClicked');
      }
    },
    render() {
      return (
        <a
          class={styles.widgetTraffic}
          href={this.widgetData.link}
          title={this.widgetData.link}
          target="_blank"
          data-test="widget-traffic"
          onClick={() => this.widgetClickHandler()}
        >
          <div
            class={[
              styles.level,
              styles[this.statusColorClass],
              styles[this.textSizeClass]
            ]}
          >
            {this.widgetData.level}
          </div>
          <p class={styles.title}>Пробки</p>
        </a>
      );
    }
  });
