import { guardEmptyString, guardUnspecified } from '@portal/utils/util-guards';
import { componentFactoryOf } from 'vue-tsx-support';

import { injectStylesMixin } from '../../../mixins/inject-styles';

import styles from './jtn-ui-main-record-item.styles.scss?module';

interface Events {
  onRecordImageClicked: Event;
  onRubricLinkClicked: Event;
  onThemeLinkClicked: { event: Event; linkName: string };
  onFormatLinkClicked: { event: Event; linkName: string };
  onRecordLinkClicked: { event: Event; linkName: string };
}

type TagType = {
  text: string;
  url: string;
};

const ICON_SIZE = 16;

export default componentFactoryOf<Events>()
  .mixin(injectStylesMixin(styles))
  .create({
    name: 'JtnUiMainRecordItem',
    props: {
      url: {
        type: String,
        default: ''
      },
      header: {
        type: String,
        default: ''
      },
      subheader: {
        type: String,
        default: ''
      },
      theme: {
        type: Object as () => TagType | null,
        default: null
      },
      rubric: {
        type: Object as () => TagType | null,
        default: null
      },
      format: {
        type: Object as () => TagType | null,
        default: null
      },
      isActiveTranslation: {
        type: Boolean,
        default: false
      },
      targetUrl: {
        type: String as () => '_self' | '_blank',
        default: '_self'
      },
      isMobileOnly: {
        type: Boolean,
        default: true
      },
      hasVideoMark: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      hasFormatBlock(): boolean {
        return guardUnspecified(this.format) && !this.isActiveTranslation;
      },
      isVideoMark(): boolean {
        return !this.isActiveTranslation && this.hasVideoMark;
      }
    },
    render() {
      const attributes = {
        attrs: this.$attrs,
        on: this.$listeners
      };
      return (
        <article
          class={[styles.record, this.hasVideoMark ? styles.hasVideos : '']}
          {...attributes}
        >
          <div class={styles.main}>
            <a
              href={this.url}
              title={this.header}
              target={this.targetUrl}
              class={[styles.imageWrap, !this.$slots.image ? styles.empty : '']}
              onClick={(event: Event) => {
                this.$emit('recordImageClicked', event);
              }}
            >
              {this.$slots.image}
            </a>
            <div class={styles.infoWrap}>
              <div
                class={[
                  styles.tags,
                  this.isActiveTranslation ? styles.withOnlineLabel : ''
                ]}
              >
                {this.isActiveTranslation && (
                  <p class={styles.onlineLabel}>{this.$slots.onlineLabel}</p>
                )}

                {this.isVideoMark && (
                  <svg class={styles.markVideo} width={ICON_SIZE} height={ICON_SIZE}>
                    <use xlinkHref="/dist/legacy/svg-sprites/jtn-critical.42b7545660e4f467e75d4b37a02533e6.svg#jtn-critical-video-outlined"></use>
                  </svg>
                )}

                {guardUnspecified(this.rubric) && (
                  <p class={styles.rubric}>
                    <a
                      class={styles.rubricLink}
                      href={this.rubric.url}
                      title={this.rubric.text}
                      onClick={(event: Event) => {
                        this.$emit('rubricLinkClicked', {
                          event,
                          linkName: this.rubric?.text
                        });
                      }}
                    >
                      {this.rubric.text}
                    </a>
                  </p>
                )}

                {guardUnspecified(this.theme) && (
                  <p class={styles.theme}>
                    <a
                      class={styles.themeLink}
                      href={this.theme.url}
                      title={this.theme.text}
                      onClick={(event: Event) => {
                        this.$emit('themeLinkClicked', {
                          event,
                          linkName: this.theme?.text
                        });
                      }}
                    >
                      {this.theme.text}
                    </a>
                  </p>
                )}

                {this.hasFormatBlock && (
                  <p class={styles.format}>
                    {guardEmptyString(this.format?.url as string) ? (
                      <a
                        class={styles.formatLink}
                        href={this.format?.url}
                        title={this.format?.text}
                        onClick={(event: Event) => {
                          this.$emit('formatLinkClicked', {
                            event,
                            linkName: this.format?.text
                          });
                        }}
                      >
                        {this.format?.text}
                      </a>
                    ) : (
                      <span>{this.format?.text}</span>
                    )}
                  </p>
                )}
              </div>

              <h3 class={styles.header} data-vr-headline>
                <a
                  class={styles.headerLink}
                  href={this.url}
                  title={this.header}
                  target={this.targetUrl}
                  onClick={(event: Event) => {
                    this.$emit('recordLinkClicked', event);
                  }}
                >
                  <span class={styles.headerText} domPropsInnerHTML={this.header} />
                </a>
              </h3>
              <div class={styles.stats}>{this.$slots.stats}</div>
            </div>
          </div>
        </article>
      );
    }
  });
