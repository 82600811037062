import { Regions } from '@smh/projects/regions';
import { componentFactory } from 'vue-tsx-support';

import { JtnUiBaseBlock, JtnUiForumItem, JtnUiRecordStatistics } from '@jtnews/jtn-ui';
import {
  injectStylesMixin,
  tsStoreMixin,
  tsBaseBlockFunctionalityMixin
} from '@jtnews/shared';

import styles from './forums-block.styles.scss?module';

type ForumItem = {
  catId: number;
  catLink: string;
  mCatLink: string;
  mRepliesLink: string;
  mSubjectLink: string;
  replies: number;
  repliesLink: string;
  subject: string;
  subjectLink: string;
  views: number;
};

const ICON_SIZE = 48;

export default componentFactory
  .mixin(injectStylesMixin(styles))
  .mixin(tsStoreMixin)
  .mixin(tsBaseBlockFunctionalityMixin)
  .create({
    name: 'ForumsBlock',
    computed: {
      isMobile(): boolean {
        return this.store.deviceInfo.isMobile && !this.store.deviceInfo.isTablet;
      },
      items(): ForumItem[] {
        return this.blockData.data as ForumItem[];
      }
    },
    methods: {
      async componentShown() {
        try {
          const res = await this.$_BaseBlockFuncMixin_newsApiClient.getForumBlock({
            regionId: this.$_BaseBlockFuncMixin_regionId as Regions
          });
          this.blockData = res.data;

          this.$_BaseBlockFuncMixin_sendReachGoalFromApi(
            this.$_BaseBlockFuncMixin_generalReachGoals
          );
        } catch (err) {
          console.error(err);
          throw err;
        }
      },
      onVisibleBlock() {
        this.sendNewReachGoal('Просмотр', 'viewForumBlock');
        this.sendNewFormatReachGoal({
          goalName: 'ViewForum',
          actionType: 'Просмотр'
        });
      },
      getIcon(categoryId: number) {
        switch (categoryId) {
          case 1:
            return (
              <svg width={ICON_SIZE} height={ICON_SIZE}>
                <use xlinkHref="/dist/legacy/svg-sprites/jtn-forums.33086d60cffeb3e140420e1d8d96e17b.svg#jtn-forums-forum"></use>
              </svg>
            );
          case 2:
            return (
              <svg width={ICON_SIZE} height={ICON_SIZE}>
                <use xlinkHref="/dist/legacy/svg-sprites/jtn-forums.33086d60cffeb3e140420e1d8d96e17b.svg#jtn-forums-business"></use>
              </svg>
            );
          case 3:
            return (
              <svg width={ICON_SIZE} height={ICON_SIZE}>
                <use xlinkHref="/dist/legacy/svg-sprites/jtn-forums.33086d60cffeb3e140420e1d8d96e17b.svg#jtn-forums-shopping"></use>
              </svg>
            );
          case 4:
            return (
              <svg width={ICON_SIZE} height={ICON_SIZE}>
                <use xlinkHref="/dist/legacy/svg-sprites/jtn-forums.33086d60cffeb3e140420e1d8d96e17b.svg#jtn-forums-comp"></use>
              </svg>
            );
          case 5:
            return (
              <svg width={ICON_SIZE} height={ICON_SIZE}>
                <use xlinkHref="/dist/legacy/svg-sprites/jtn-forums.33086d60cffeb3e140420e1d8d96e17b.svg#jtn-forums-auto"></use>
              </svg>
            );
          case 6:
            return (
              <svg width={ICON_SIZE} height={ICON_SIZE}>
                <use xlinkHref="/dist/legacy/svg-sprites/jtn-forums.33086d60cffeb3e140420e1d8d96e17b.svg#jtn-forums-realty"></use>
              </svg>
            );
          case 7:
            return (
              <svg width={ICON_SIZE} height={ICON_SIZE}>
                <use xlinkHref="/dist/legacy/svg-sprites/jtn-forums.33086d60cffeb3e140420e1d8d96e17b.svg#jtn-forums-tourism"></use>
              </svg>
            );
          case 8:
            return (
              <svg width={ICON_SIZE} height={ICON_SIZE}>
                <use xlinkHref="/dist/legacy/svg-sprites/jtn-forums.33086d60cffeb3e140420e1d8d96e17b.svg#jtn-forums-leisure"></use>
              </svg>
            );
          case 9:
            return (
              <svg width={ICON_SIZE} height={ICON_SIZE}>
                <use xlinkHref="/dist/legacy/svg-sprites/jtn-forums.33086d60cffeb3e140420e1d8d96e17b.svg#jtn-forums-family"></use>
              </svg>
            );
          case 11:
            return (
              <svg width={ICON_SIZE} height={ICON_SIZE}>
                <use xlinkHref="/dist/legacy/svg-sprites/jtn-forums.33086d60cffeb3e140420e1d8d96e17b.svg#jtn-forums-community"></use>
              </svg>
            );
          case 13:
            return (
              <svg width={ICON_SIZE} height={ICON_SIZE}>
                <use xlinkHref="/dist/legacy/svg-sprites/jtn-forums.33086d60cffeb3e140420e1d8d96e17b.svg#jtn-forums-repair"></use>
              </svg>
            );
          case 14:
            return (
              <svg width={ICON_SIZE} height={ICON_SIZE}>
                <use xlinkHref="/dist/legacy/svg-sprites/jtn-forums.33086d60cffeb3e140420e1d8d96e17b.svg#jtn-forums-job"></use>
              </svg>
            );
          case 16:
            return (
              <svg width={ICON_SIZE} height={ICON_SIZE}>
                <use xlinkHref="/dist/legacy/svg-sprites/jtn-forums.33086d60cffeb3e140420e1d8d96e17b.svg#jtn-forums-she"></use>
              </svg>
            );
          case 19:
            return (
              <svg width={ICON_SIZE} height={ICON_SIZE}>
                <use xlinkHref="/dist/legacy/svg-sprites/jtn-forums.33086d60cffeb3e140420e1d8d96e17b.svg#jtn-forums-pets"></use>
              </svg>
            );
          case 28:
            return (
              <svg width={ICON_SIZE} height={ICON_SIZE}>
                <use xlinkHref="/dist/legacy/svg-sprites/jtn-forums.33086d60cffeb3e140420e1d8d96e17b.svg#jtn-forums-sport"></use>
              </svg>
            );
          case 1000:
            return (
              <svg width={ICON_SIZE} height={ICON_SIZE}>
                <use xlinkHref="/dist/legacy/svg-sprites/jtn-forums.33086d60cffeb3e140420e1d8d96e17b.svg#jtn-forums-finds"></use>
              </svg>
            );
          case 1001:
            return (
              <svg width={ICON_SIZE} height={ICON_SIZE}>
                <use xlinkHref="/dist/legacy/svg-sprites/jtn-forums.33086d60cffeb3e140420e1d8d96e17b.svg#jtn-forums-love"></use>
              </svg>
            );
          case 1002:
            return (
              <svg width={ICON_SIZE} height={ICON_SIZE}>
                <use xlinkHref="/dist/legacy/svg-sprites/jtn-forums.33086d60cffeb3e140420e1d8d96e17b.svg#jtn-forums-food"></use>
              </svg>
            );
          case 1003:
            return (
              <svg width={ICON_SIZE} height={ICON_SIZE}>
                <use xlinkHref="/dist/legacy/svg-sprites/jtn-forums.33086d60cffeb3e140420e1d8d96e17b.svg#jtn-forums-help"></use>
              </svg>
            );
          case 1004:
            return (
              <svg width={ICON_SIZE} height={ICON_SIZE}>
                <use xlinkHref="/dist/legacy/svg-sprites/jtn-forums.33086d60cffeb3e140420e1d8d96e17b.svg#jtn-forums-woman"></use>
              </svg>
            );
          case 1005:
            return (
              <svg width={ICON_SIZE} height={ICON_SIZE}>
                <use xlinkHref="/dist/legacy/svg-sprites/jtn-forums.33086d60cffeb3e140420e1d8d96e17b.svg#jtn-forums-creative"></use>
              </svg>
            );
          case 1006:
            return (
              <svg width={ICON_SIZE} height={ICON_SIZE}>
                <use xlinkHref="/dist/legacy/svg-sprites/jtn-forums.33086d60cffeb3e140420e1d8d96e17b.svg#jtn-forums-pets-plants"></use>
              </svg>
            );
          case 1007:
            return (
              <svg width={ICON_SIZE} height={ICON_SIZE}>
                <use xlinkHref="/dist/legacy/svg-sprites/jtn-forums.33086d60cffeb3e140420e1d8d96e17b.svg#jtn-forums-fish"></use>
              </svg>
            );
          case 1008:
            return (
              <svg width={ICON_SIZE} height={ICON_SIZE}>
                <use xlinkHref="/dist/legacy/svg-sprites/jtn-forums.33086d60cffeb3e140420e1d8d96e17b.svg#jtn-forums-eco"></use>
              </svg>
            );

          default:
            return (
              <svg width={ICON_SIZE} height={ICON_SIZE}>
                <use xlinkHref="/dist/legacy/svg-sprites/jtn-forums.33086d60cffeb3e140420e1d8d96e17b.svg#jtn-forums-forum"></use>
              </svg>
            );
        }
      },
      forumClick(valueName: string, prop1: string, prop2: string) {
        this.sendNewReachGoal(valueName, 'clickForumBlock');
        this.sendNewFormatReachGoal({
          goalName: 'ClickForum',
          actionType: 'Клик',
          prop1,
          prop2
        });
      },
      sendNewReachGoal(valueName: string, goalName: string) {
        const blockType = 'Центральная колонка';
        const fieldType = 'Форумы (блок)';

        this.store.analyticsModule.sendNewReachGoal({
          blockType,
          fieldType,
          valueName,
          goalName,
          willDeprecate: true
        });
      },
      sendNewFormatReachGoal({
        goalName,
        actionType,
        prop1,
        prop2
      }: {
        goalName: string;
        actionType: string;
        prop1?: string;
        prop2?: string;
      }) {
        this.store.analyticsModule.sendNewFormatReachGoal({
          blockType: 'Форумы',
          goalName,
          actionType,
          prop1,
          prop2
        });
      }
    },
    render() {
      return (
        <div v-observe-visibility={this.obsVisibilityOptions} class={styles.forumsBlock}>
          {this.$_BaseBlockFuncMixin_canRender && (
            <JtnUiBaseBlock
              v-observe-visibility={this.obsHalfOptions}
              header={this.$_BaseBlockFuncMixin_params.title as string}
              url={this.$_BaseBlockFuncMixin_params.link as string}
              class={styles.blockWrapper}
              onClickToHeader={() =>
                this.forumClick(
                  'Форумы (заголовок)',
                  this.$_BaseBlockFuncMixin_params.link as string,
                  'Форумы'
                )
              }
            >
              <div class={styles.list}>
                {this.items.map((item: ForumItem, index: number) => (
                  <JtnUiForumItem
                    key={index}
                    catLink={
                      this.isMobile && item.mCatLink ? item.mCatLink : item.catLink
                    }
                    subjectLink={
                      this.isMobile && item.mSubjectLink
                        ? item.mSubjectLink
                        : item.subjectLink
                    }
                    subject={item.subject}
                    class={styles.item}
                    onClickedIconLink={() =>
                      this.forumClick('Переход на форум (иконка)', item.catLink, 'Иконка')
                    }
                    onClickedSubjectLink={() =>
                      this.forumClick('Переход на тему', item.subjectLink, 'Заголовок')
                    }
                  >
                    <template slot="icon">{this.getIcon(item.catId)}</template>
                    <template slot="stats">
                      <JtnUiRecordStatistics
                        viewsCount={item.views}
                        commentsCount={item.replies}
                        commentsUrl={
                          this.isMobile && item.mRepliesLink
                            ? item.mRepliesLink
                            : item.repliesLink
                        }
                        isCommentsAllowed={true}
                        discussUrl={
                          this.isMobile && item.mRepliesLink
                            ? item.mRepliesLink
                            : item.repliesLink
                        }
                        onCommentsClicked={() =>
                          this.forumClick(
                            'Переход на тему (комментарии)',
                            item.repliesLink,
                            'Комментарии'
                          )
                        }
                        onDiscussClicked={() =>
                          this.forumClick(
                            'Переход на тему (комментарии)',
                            item.repliesLink,
                            'Комментарии'
                          )
                        }
                      />
                    </template>
                  </JtnUiForumItem>
                ))}
              </div>
            </JtnUiBaseBlock>
          )}
        </div>
      );
    }
  });
