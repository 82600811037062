import {
  getResponsiveImageData,
  ImageDecoding,
  ImageLoading,
  ImageSourceVM,
  ResponsiveImageValues,
} from '@jtnews/shared/images';

import { Image } from '../../domain';

type VideoDayData = Image & ImageData;

type ImageData = {
  src: string;
  sources: ImageSourceVM[];
  decoding?: ImageDecoding;
  loading?: ImageLoading;
};

const getImageData = (input: {
  url: string;
  width: number;
  values: ResponsiveImageValues[];
  isMain: boolean;
  hasCommercialLabel: boolean;
}) => {
  const { url, width, values, isMain, hasCommercialLabel } = input;
  const info = getResponsiveImageData({
    url,
    width,
    values,
    hasCommercialLabel,
    isPreloadImage: isMain,
    isLazy: !isMain,
  });

  return {
    src: info.data.src,
    sources: info.data.sources,
    decoding: info.data.decoding,
    loading: info.data.loading,
    hasCommercialLabel: info.data.hasCommercialLabel,
  };
};

export const createVideoDayPreview = (photo: Image): VideoDayData => {
  const values = [
    {
      breakpoint: 0,
      width: photo.width,
      noMedia: true,
    },
  ];

  return {
    ...photo,
    ...getImageData({
      url: photo.url,
      width: photo.width,
      values,
      isMain: false,
      hasCommercialLabel: photo.hasCommercialLabel,
    }),
  };
};
