import { componentFactoryOf } from 'vue-tsx-support';

import { injectStylesMixin } from '@jtnews/shared';

import styles from './widget-currency.styles.scss?module';

type Currency = {
  delta: number;
  name: string;
  nominal: number;
  value: number;
};

type WidgetData = {
  currency: Currency[];
  link: string;
};

type Events = {
  onCurrencyWidgetClicked: void;
};

export default componentFactoryOf<Events>()
  .mixin(injectStylesMixin(styles))
  .create({
    name: 'WidgetCurrencyRedesign',
    props: {
      widgetData: {
        type: Object as () => WidgetData,
        default: null
      }
    },
    methods: {
      formatValue(value: number, exp: number): string {
        return parseFloat(value.toString()).toFixed(exp);
      },
      widgetClickHandler(): void {
        this.$emit('currencyWidgetClicked');
      }
    },
    render() {
      return (
        <a
          class={styles.widgetCurrency}
          href={this.widgetData.link}
          title={this.widgetData.link}
          target="_blank"
          data-test="widget-currency"
          onClick={() => this.widgetClickHandler()}
        >
          <div class={styles.list}>
            {this.widgetData.currency.map(item => [
              <span class={styles.name}>{item.name}</span>,
              <span class={styles.value}>{this.formatValue(item.value, 2)}</span>,
              <span class={[styles.icon, item.delta < 0 ? styles.rotate : '']}>
                <svg fill="currentColor" width={16} height={16}>
                  <use xlinkHref="/dist/legacy/svg-sprites/jtn-critical.42b7545660e4f467e75d4b37a02533e6.svg#jtn-critical-expand-more"></use>
                </svg>
              </span>
            ])}
          </div>
        </a>
      );
    }
  });
