import { debounce } from '@portal/utils/util-debounce';
import { guardUnspecified } from '@portal/utils/util-guards';
import { Regions } from '@smh/projects/regions';
import { componentFactoryOf } from 'vue-tsx-support';

import { injectStylesMixin, ObserverVisibility, tsStoreMixin } from '@jtnews/shared';

import styles from './viqeo.styles.scss?module';

const VIQEO_SCRIPT = {
  src: 'https://cdn.viqeo.tv/js/vq_starter.js'
};

const VIQEO_RENDER_DELAY = 500;

let isDistanceListenerSet = false;

let viqeoIntersectionObserver: IntersectionObserver | null = null;

// Маппинг плеера к его расстоянию до середины экрана
const PLAYERS_MAP = new Map<viqeo.Player, number>();

// Минимальное расстояние от плеера до середины экрана
let leastDistance = Infinity;

// Сет из плееров, которые юзер остановил самостоятельно
const MANUALLY_STOPPED_PLAYERS = new Set<viqeo.Player>();

const shouldPlay = (player: viqeo.Player) => {
  const distance = PLAYERS_MAP.get(player) ?? Infinity;
  return distance <= leastDistance && leastDistance !== Infinity;
};

const stopOrPausePlayers = () => {
  PLAYERS_MAP.forEach((distance, instance) => {
    if (!MANUALLY_STOPPED_PLAYERS.has(instance) && shouldPlay(instance)) {
      instance.play();
    } else {
      instance.pause();
    }
  });
};

const recalculateDistance = () => {
  leastDistance = Infinity;

  PLAYERS_MAP.forEach((key, instance) => {
    const { top, height } = instance.iframe?.container!.getBoundingClientRect();

    const centerOfVideo = top + height / 2;

    if (centerOfVideo < 0 || centerOfVideo > window.innerHeight) {
      return PLAYERS_MAP.set(instance, Infinity);
    }

    const distance = Math.abs(window.innerHeight / 2 - centerOfVideo);
    PLAYERS_MAP.set(instance, distance < window.innerHeight / 2 ? distance : Infinity);

    leastDistance = Math.min(leastDistance, distance);
  });

  stopOrPausePlayers();
};

const EVENT_NAMES: viqeo.ViqeoPlayerEvents[] = [
  'Player:started',
  'Player:ended',
  'Player:replayed',
  'Player:advEnded',
  'Player:paused',
  'Player:played',
  'Player:videoLoaded'
];

const RG_CONFIG: Partial<
  Record<viqeo.ViqeoPlayerEvents, { goalName: string; action: string }>
> = {
  ['Player:started']: {
    goalName: 'VideoStart',
    action: 'Start'
  },
  ['Player:paused']: {
    goalName: 'VideoPause',
    action: 'Pause'
  },
  ['Player:played']: {
    goalName: 'VideoPlay',
    action: 'Play'
  },
  ['Player:ended']: {
    goalName: 'VideoEnd',
    action: 'End'
  },
  ['Player:replayed']: {
    goalName: 'VideoRePlay',
    action: 'RePlay'
  },
  ['Player:advEnded']: {
    goalName: 'VideoAdvViewed',
    action: 'AdvViewed'
  }
};

type ComponentData = {
  viqeoObsVisibility: ObserverVisibility;
  isFirstPlayed: boolean;
  player: viqeo.Player | null;
};

type PlayerParams = {
  videoId?: string;
  profileId: number;
  videoSrc?: string;
  playerId?: number;
  previewSrc?: string;
};

type Events = {
  onViqeoPlayerEvent: {
    eventType: string;
    videoId: string;
    goalName: string;
    action: string;
    playerType: string;
    duration: number;
  };
  onPlayed: void;
};

type PlayerType = 'standart' | 'external' | 'external-youtube';

type PlayerAutoplayStrategy = 'default' | 'custom';

export default componentFactoryOf<Events>()
  .mixin(injectStylesMixin(styles))
  .mixin(tsStoreMixin)
  .create({
    name: 'Viqeo',
    props: {
      type: {
        type: String as () => PlayerType,
        default: 'standart'
      },
      params: {
        type: Object as () => PlayerParams
      },
      autoplayStrategy: {
        type: String as () => PlayerAutoplayStrategy,
        default: 'default'
      }
    },
    data(): ComponentData {
      return {
        viqeoObsVisibility: new ObserverVisibility('half'),
        isFirstPlayed: false,
        player: null
      };
    },
    beforeMount() {
      void this.store.commonModule.externalScriptsService.injectScriptToHead(
        VIQEO_SCRIPT
      );
    },
    computed: {
      regionId(): Regions {
        return this.store.regionId;
      },
      videoId(): string {
        return this.params.videoId || this.params.videoSrc || '';
      },
      callbacksLoaded(): boolean {
        return this.store.commonModule.isViqeoCallbacksLoaded;
      },
      isYoutube() {
        return this.type === 'external-youtube';
      }
    },
    mounted() {
      this.init();
      this.viqeoObsVisibility.event.subscribe(state => {
        if (state.value) {
          setTimeout(() => this.emitViewed(), VIQEO_RENDER_DELAY);
        }
      });
    },
    methods: {
      init() {
        if (window.VIQEO) {
          void this.start();
        } else if (typeof window.onViqeoLoad === 'undefined') {
          window.onViqeoLoad = [this.start.bind(this)];
        } else if (typeof window.onViqeoLoad === 'function') {
          window.onViqeoLoad = [window.onViqeoLoad, this.start.bind(this)];
        } else {
          window.onViqeoLoad.push(this.start.bind(this));
        }
      },
      async start() {
        const { initPlayer, subscribeTracking } = await import('./viqeo-player');

        const viqeoParams: viqeo.FactoryParams = {
          parent: this.$refs.viqeoVideo as Element,
          customTag: 'client-type-web',
          ...this.params
        };

        this.player = await initPlayer(viqeoParams);

        if (this.autoplayStrategy === 'custom') {
          // TODO: Не срабатывает хук на ресайз видео, не правильный размер https://jira.rn/browse/REGIONNEWS-33052
          // PLAYERS_MAP.set(viqeoPlayer, Infinity);
          // this.setupCustomAutoplay();
        }

        if (!this.callbacksLoaded) {
          this.store.commonModule.updateViqeoCallbacksState(true);
          EVENT_NAMES.forEach(name => {
            subscribeTracking(event => {
              this.viqeoEventsHandle(event);
            }, name);
          });
        }
      },
      setupCustomAutoplay(viqeoPlayer: viqeo.Player) {
        if (viqeoIntersectionObserver === null) {
          const observerOptions = {
            threshold: 0
          };

          const debouncedRecalculateDistance = debounce(
            recalculateDistance,
            50
            // TODO: поправить ошибку в типах debounce из @portal/utils (REGIONNEWS-32752)
          ) as () => void;

          viqeoIntersectionObserver = new IntersectionObserver(entries => {
            const isInViewport = entries.some(entry => entry.intersectionRatio > 0);

            if (!isDistanceListenerSet && isInViewport) {
              isDistanceListenerSet = true;
              recalculateDistance();
              document.addEventListener('scroll', debouncedRecalculateDistance);
            } else if (!isInViewport) {
              isDistanceListenerSet = false;
              recalculateDistance();
              document.removeEventListener('scroll', debouncedRecalculateDistance);
            }
          }, observerOptions);
        }

        const container = viqeoPlayer?.iframe?.container;

        if (guardUnspecified(container)) {
          viqeoIntersectionObserver.observe(container);
        }
      },
      emitViewed() {
        const playerType =
          this.params.videoSrc && this.params.videoSrc.length > 0 ? 'ViqeoExt' : 'Viqeo';
        const eventType = `Просмотр ${playerType}`;
        this.$emit('viqeoViewed');
        this.$emit('viqeoPlayerEvent', {
          eventType,
          videoId: this.videoId,
          goalName: 'VideoView',
          playerType,
          action: 'Просмотр',
          duration: this.player?.getDuration() ?? 0
        });
      },
      viqeoEventsHandle(event: viqeo.ViqeoEventPayload) {
        if (event.eventName === 'Player:played') {
          this.$emit('played');
        }

        if (event.eventName === 'Player:paused' && shouldPlay(event.player)) {
          return MANUALLY_STOPPED_PLAYERS.add(event.player);
        }

        if (event.eventName === 'Player:played' && shouldPlay(event.player)) {
          return MANUALLY_STOPPED_PLAYERS.delete(event.player);
        }

        if (event.eventName === 'Player:videoLoaded') {
          return recalculateDistance();
        }

        if (event.eventName === 'Player:started') {
          if (!this.isFirstPlayed) {
            this.isFirstPlayed = true;
            this.$emit('firstPlayed');
          }
        }

        if (event.container === 'recommendPlayer') {
          return;
        }
        const playerType = event.container === 'stdPlayer' ? 'Viqeo' : 'ViqeoExt';
        const videoId =
          playerType === 'Viqeo' ? event.videoId : event.originalParams.videoSrc;
        const value = RG_CONFIG[event.eventName];

        if (!guardUnspecified(value)) {
          return;
        }
        const eventType = `${value.action} ${playerType}`;
        const { goalName } = value;
        this.$emit('viqeoPlayerEvent', {
          eventType,
          videoId,
          goalName,
          playerType,
          action: value.action,
          duration: this.player?.getDuration() ?? 0
        });
      }
    },
    render() {
      return (
        <div
          v-observe-visibility={this.viqeoObsVisibility.getOptions('half')}
          class={styles.viqeoContainer}
          ref="viqeoVideo"
        />
      );
    }
  });
