<template>
  <div :class="$style.windowStats">
    <jtn-ui-record-statistics-will-deprecate
      v-if="!isNewDesign"
      :views="data.viewsCount"
      :comments="data.commentsCount"
      :comments-url="data.urls.urlComments"
      :comments-enabled="data.isCommentsAllowed"
      :is-white-style="newMain"
      :discuss-text="$_RecordItemUtilsMixin_discussText"
      :discuss-url="$_RecordItemUtilsMixin_getDiscussUrl(data.urls.urlComments)"
      :target-url="targetLink"
      @discuss="
        goToDiscussLink(
          $event,
          $_RecordItemUtilsMixin_getDiscussUrl(data.urls.urlComments)
        )
      "
      @commentsClicked="clickedLink()"
    />
    <jtn-ui-record-statistics
      v-else
      :views-count="data.viewsCount"
      :is-comments-allowed="data.isCommentsAllowed"
      :comments-count="data.commentsCount"
      :comments-url="data.urls.urlComments"
      :discuss-text="$_RecordItemUtilsMixin_discussText"
      :discuss-url="$_RecordItemUtilsMixin_getDiscussUrl(data.urls.urlComments)"
      :target="targetLink"
      :color="isMain ? 'white' : ''"
      @onDiscussClicked="
        goToDiscussLink(
          $event,
          $_RecordItemUtilsMixin_getDiscussUrl(data.urls.urlComments)
        )
      "
      @onCommentsClicked="clickedLink()"
    />
    <jtn-ui-bookmark
      class="windowStatsBookmark"
      :is-added="isInBookmarks"
      :color="isMain ? 'white' : ''"
      @clickedBookmark="emitClickedBookmark"
    />
  </div>
</template>

<script>
import { JtnUiRecordStatisticsWillDeprecate } from '@jtn-ui/components/record';
import { JtnUiBookmark, JtnUiRecordStatistics } from '@jtnews/jtn-ui';
import { isAbsoluteUrl } from '@portal/utils/util-url';
import EntityDecoderMixin from '../../mixins/entity-decoder.mixin';
import { RecordItemUtilsMixin } from '../../mixins';

export default {
  name: 'WindowRecordStats',
  components: {
    JtnUiRecordStatisticsWillDeprecate,
    JtnUiRecordStatistics,
    JtnUiBookmark
  },
  mixins: [RecordItemUtilsMixin, EntityDecoderMixin],
  props: {
    data: {
      type: Object,
      default() {
        return {};
      }
    },
    hasTooltip: {
      type: Boolean,
      default: false
    },
    isNewDesign: {
      type: Boolean,
      default: false
    },
    isMain: {
      type: Boolean,
      default: false
    },
    newMain: {
      type: Boolean,
      default: false
    },
    isInBookmarks: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    targetLink() {
      return isAbsoluteUrl(this.data.urls.urlComments) ? '_blank' : '_self';
    }
  },
  methods: {
    clickedLink() {
      this.$emit('onCommentsClicked');
    },
    goToDiscussLink(event, url) {
      this.$emit('clickDiscussLink', { event, url });
    },
    emitClickedBookmark() {
      this.$emit('clickedBookmark');
    }
  }
};
</script>

<style lang="scss" module>
.windowStats {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
