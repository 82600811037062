<template>
  <section
      v-observe-visibility="obsVisibilityOptions"
      v-show="!hideWidget"
      class="widgetVideoDay videos"
  >
    <widget-gallery-slider
        v-observe-visibility="obsHalfOptions"
        v-if="renderWidgetSliderWrapper"
        :title="title"
        :title-link="videosPageUrl"
        :has-thumbs="!isMobile"
        :has-black-theme="true"
        :show-loader="!dataLoaded"
        :set-video-heigth="true"
        :has-push-from-last-slide="true"
        nav-buttons-type="over_photo"
        theme="dark"
        :media-content-class-name="mediaContentClassName"
        :slider-content-class-name="sliderContentClassName"
        @slideChanged="slideChanged"
        @swiped="slideSwiped($event)"
        @navBtnClicked="navBtnClicked($event)"
        @titleClicked="sendReachGoalsForTitleClicked"
    >
      <template slot="slides">
        <template v-for="(item, index) of items">
          <div :key="item.id" class="sliderItem">
            <div :class="sliderContentClassName">
              <div class="sliderItemHeaderContainer">
                <div>
                  <jtn-ui-typography
                      tag="h3"
                      type="font-videoday-type-1"
                      link-type="link-white"
                      class="sliderItemHeader"
                  >
                    <a
                        v-if="item.url"
                        :href="item.url"
                        :title="entityDecode(item.header)"
                        :target="item.url.indexOf('http') < 0 ? '_self' : '_blank'"
                        @click="
                        navigateFromHeader(item.url, 'Переход на материал', 'Заголовок')
                      "
                        v-html="item.header"
                    />

                    <span v-else v-html="item.header"/>
                  </jtn-ui-typography>

                  <jtn-ui-typography
                      v-if="item.url"
                      tag="a"
                      type="font-photoday-type-1"
                      link-type="link-blue"
                      :href="item.url"
                      :target="item.url.indexOf('http') < 0 ? '_self' : '_blank'"
                      class="sliderItemHeaderLink"
                      @click="navigateFromHeader(item.url, 'Смотреть материал')"
                  >
                    Смотреть материал
                  </jtn-ui-typography>
                </div>

                <div class="sliderItemInfo">
                  <div class="sliderItemInfoText">
                    <jtn-ui-typography
                        v-if="item.video.author"
                        tag="span"
                        type="font-photoday-type-1"
                        class="sliderItemInfoItem author"
                        v-html="item.video.author"
                    />
                    <jtn-ui-typography
                        tag="span"
                        type="font-photoday-type-1"
                        class="sliderItemInfoItem date"
                    >
                      {{ dateFormat(item.publishAt) }}
                    </jtn-ui-typography>
                  </div>

                  <jtn-ui-share
                      theme="dark"
                      :btn-size="36"
                      :is-popup="true"
                      :popup-position="isMobile ? 'top-right' : 'bottom-right'"
                      :is-widget-share="true"
                      class="sliderItemInfoBtn"
                      @shared="sharedVideo($event, item.id, item.header, item.url)"
                  />
                </div>
              </div>
              <div class="mediaContent">
                <jtn-video
                    v-show="index === activeIndex || videosUrls[index] !== ''"
                    ref="video"
                    :class="mediaContentClassName"
                    :video-url="videosUrls[index]"
                    :video-sources="videosSources[index]"
                    @onClickedVideo="onClickedVideo(item)"
                />
              </div>
            </div>
          </div>
        </template>
      </template>

      <template slot="thumbs">
        <template v-for="item of items">
          <div v-if="!isMobile" :key="item.id" class="thumbItem" @click="sendThumbsCTR()">
            <div class="previewVideo">
              <JtnUiImage
                :src="item.previewImage.src"
                :sources="item.previewImage.sources"
                :loading="item.previewImage.loading"
                :decoding="item.previewImage.decoding"
                :width="previewImgWidth"
                :height="previewImgHeight"
              />
            </div>
          </div>
        </template>
      </template>
    </widget-gallery-slider>
  </section>
</template>

<script>
import { dateFormat } from '@portal/utils/util-date';
import {
  PHOTO_AND_VIDEO_PAGE_SIZES,
  VIDEO_WIDGET_VISIBLE_PAGES_COUNT
} from '@jtnews/shared/data';
import { JtnUiTypography, JtnUiShare } from '@jtnews/jtn-ui';

import { DEFAULT_DATE_FORMAT } from '@jtnews/shared/data';
import { createVideoDayPreview } from '@jtnews/shared/news';
import { smoothScroll } from '@jtnews/shared/smooth-scroll';
import { JtnUiImage } from '@jtnews/jtn-ui';

import { StoreMixin } from '../../mixins';
import EntityDecoderMixin from '../../mixins/entity-decoder.mixin';
import { BaseBlockFunctionalityMixin } from '../../mixins/base-block-functionality.mixin';
import JtnVideo from '../../components/jtn-video';

import { WidgetGallerySlider } from '../../widgets/widget-gallery-slider';

const WIDTH_PREVIEW = 184;
const HEIGHT_PREVIEW = 104;
const PAGESIZE = VIDEO_WIDGET_VISIBLE_PAGES_COUNT * PHOTO_AND_VIDEO_PAGE_SIZES;

export default {
  name: 'WidgetVideoDay',
  components: {
    JtnUiTypography,
    WidgetGallerySlider,
    JtnUiShare,
    JtnUiImage,
    JtnVideo
  },
  mixins: [EntityDecoderMixin, StoreMixin, BaseBlockFunctionalityMixin],
  data() {
    return {
      renderWidgetSliderWrapper: false,
      hideWidget: false,
      dataLoaded: false,
      page: 1,
      items: [],
      shareVideo: null,
      formatDate: DEFAULT_DATE_FORMAT,
      activeIndex: 0,
      videosUrls: {},
      videosSources: {},
      sliderContentClassName: 'sliderItemContent',
      mediaContentClassName: 'mediaContent',
      previewImgWidth: 188,
      previewImgHeight: 108,
    };
  },
  computed: {
    regionId() {
      return this.store.regionId;
    },
    newsApiClient() {
      return this.store.newsApiClient;
    },
    isMobile() {
      return this.store.deviceInfo.isMobile && !this.store.deviceInfo.isTablet;
    },
    params() {
      if (this.blockData && this.blockData.params) {
        return this.blockData.params;
      }

      return {};
    },
    title() {
      return this.params.title ? this.params.title : '';
    },
    videosPageUrl() {
      return this.params.path ? this.params.path : '';
    }
  },
  watch: {
    activeIndex() {
      this.addVideo();
    }
  },
  beforeMount() {
    if (this.$route.query.shareVideoDay !== undefined) {
      this.obsVisibilityOptions = undefined;
      this.getVideoById(this.$route.query.shareVideoDay);
    }
  },
  methods: {
    onVisibleBlock() {
      const firstSlide = this.items[0];

      this.sendNewReachGoal('Просмотр', 'viewDayVideoBlock');
      this.sendNewFormatReachGoal({
        goalName: 'ViewVideoDay',
        actionType: 'Просмотр',
        prop1:
            firstSlide.url !== '' ? this.getRecordIdFromUrl(firstSlide.url) : firstSlide.id
      });
    },
    async componentShown() {
      await this.fetch();
    },
    async fetch() {
      try {
        const res = await this.newsApiClient.getVideosOfTheDayBlock({
          regionId: this.regionId,
          pagesize: PAGESIZE,
          page: this.page
        });

        this.blockData = res.data;

        const newImages = this.checkSharingVideo(this.blockData.data)
          .map(data => ({
            ...data,
            previewImage: createVideoDayPreview(data.video.previewImage)
          }))

        this.items = [...this.items, ...newImages];

        if (this.items && this.items.length > 0) {
          for (let index = 0; index < this.items.length; index++) {
            if (this.videosUrls[index] !== '') {
              this.videosUrls[index] = '';
            }
          }

          this.addVideo();

          this.dataLoaded = true;
          this.renderWidgetSliderWrapper = true;
        } else {
          this.hideWidget = true;
        }
      } catch (err) {
        this.hideWidget = true;
        console.log(err);
        throw err;
      }
    },
    async getVideoById(recordId) {
      try {
        this.shareVideo = await this.newsApiClient.getVideoOfTheDayById(
            this.regionId,
            recordId
        );
        this.items = [this.shareVideo];
        this.fetch();
      } catch (err) {
        console.log(err);
      }
    },
    checkSharingVideo(items) {
      if (this.shareVideo) {
        const videos = items.filter(video => video.id !== this.shareVideo.id);

        setTimeout(this.scrollToWidget, 1000);

        return videos;
      }

      return items;
    },
    sharedVideo(event, id, header, url) {
      const valueName = {
        Поделиться: event
      };
      this.sendNewReachGoal(valueName, 'clickDayVideoBlock');
      this.sendNewFormatReachGoal({
        goalName: 'ClickVideoDay',
        actionType: 'Клик',
        prop1: url !== '' ? this.getRecordIdFromUrl(url) : id,
        prop2: `Поделиться ${event}`
      });

      this.store.sharingModule.shareVideoDay({
        socialTarget: event,
        id: id.toString(),
        title: this.entityDecode(header),
        utmParams: this.store.sharingModule.getUtmParams(
            'sharevideodaymain',
            this.getUtmCampaign(url),
            `${this.regionId}`
        )
      });
    },
    slideChanged($event) {
      this.$refs.video[this.activeIndex].stopVideo();
      this.activeIndex = $event.current;

      this.checkPushToVideoPage();
    },
    slideSwiped(event) {
      if (this.store.deviceInfo.isMobile || this.store.deviceInfo.isTablet) {
        this.sendNewFormatReachGoal({
          goalName: 'ScrollVideoDay',
          actionType: 'Скролл',
          prop1: event === 'prev' ? 'Влево' : 'Вправо'
        });
      }
    },
    checkPushToVideoPage() {
      if (this.activeIndex === this.items.length) {
        location.href = '/videos/';
      }
    },
    navBtnClicked(event) {
      const valueName = event === 'prev' ? 'стрелка влево' : 'стрелка вправо';
      this.sendNewReachGoal(valueName, 'clickDayVideoBlock');
      this.sendNewFormatReachGoal({
        goalName: 'ScrollVideoDay',
        actionType: 'Скролл',
        prop1: event === 'prev' ? 'Влево' : 'Вправо'
      });
    },
    dateFormat(dateStr) {
      try {
        return dateFormat(dateStr, this.formatDate);
      } catch (e) {
        return '';
      }
    },
    scrollToWidget() {
      if (this.$el) {
        setTimeout(() => {
          const clientRect = this.$el.getBoundingClientRect();
          const top = clientRect.top + window.pageYOffset;

          smoothScroll(top, 900);
        });
      }
    },
    navigateFromHeader(url, valueName, valueNameNewFormat) {
      this.sendNewReachGoal(valueName, 'clickDayVideoBlock');
      this.sendNewFormatReachGoal({
        goalName: 'ClickVideoDay',
        actionType: 'Клик',
        prop1: this.getRecordIdFromUrl(url),
        prop2: valueNameNewFormat ? valueNameNewFormat : valueName
      });
    },
    sendThumbsCTR() {
      this.sendNewReachGoal('Листалка (превью)', 'clickDayVideoBlock');
      this.sendNewFormatReachGoal({
        goalName: 'ScrollVideoDay',
        actionType: 'Скролл',
        prop1: 'Превью'
      });
    },
    onClickedVideo(item) {
      this.sendNewReachGoal('Просмотр видео', 'clickDayVideoBlock');
      this.sendNewFormatReachGoal({
        goalName: 'ClickVideoDay',
        actionType: 'Клик',
        prop1: item.url !== '' ? this.getRecordIdFromUrl(item.url) : item.id,
        prop2: 'Просмотр видео'
      });
    },
    addVideo() {
      if (this.videosUrls[this.activeIndex] === '') {
        this.videosUrls[this.activeIndex] = this.items[this.activeIndex].video.url;
        this.videosSources[this.activeIndex] =
            this.items[this.activeIndex].video.sourceName;
      }
    },
    sendReachGoalsForTitleClicked() {
      this.sendNewReachGoal('Видео дня (заголовок)', 'clickDayVideoBlock');
      this.sendNewFormatReachGoal({
        goalName: 'ClickVideoDay',
        actionType: 'Клик',
        prop1: this.videosPageUrl,
        prop2: 'Видео дня'
      });
    },
    sendNewReachGoal(valueName, goalName) {
      const blockType = 'Центральная колонка';
      const fieldType = 'Видео дня (блок)';

      this.store.analyticsModule.sendNewReachGoal({
        blockType,
        fieldType,
        valueName,
        goalName,
        willDeprecate: true
      });
    },
    sendNewFormatReachGoal({ goalName, actionType, prop1, prop2 }) {
      this.store.analyticsModule.sendNewFormatReachGoal({
        blockType: 'Видео дня',
        goalName,
        actionType,
        prop1,
        prop2
      });
    },
    // TODO избавиться от метода, когда бек добавит id материала в данные REGIONNEWS-31863
    getRecordIdFromUrl(url) {
      const id = url.match(/\d{8}/gi);
      return id ? id[0] : url;
    },
    getUtmCampaign(url) {
      const id = url.match(/\d{8}/gi);
      return id ? id[0] : 'none';
    }
  }
};
</script>

<style lang="scss" scoped>
@import 'styles';
@import '../../../styles/swiper-slider';

.widgetVideoDay {
  display: flex;

  box-sizing: border-box;
  min-height: 370px;

  margin: 0 $central-column-mobile-padding-negative;
  padding: 0 $central-column-mobile-padding;

  background: $color-surface-700;

  @include on-min-resize($tablet) {
    min-height: 441px;

    margin: 0 -#{$central-column-desktop-padding};
    padding: 0 $central-column-desktop-padding;
  }
  @include on-min-resize($laptop) {
    min-height: 480px;
  }
  @include on-min-resize($desktop) {
    min-height: 650px;
  }

  .previewVideo {
    position: relative;
    z-index: -1;
  }
}

.sliderItemHeaderContainer {
  @include on-min-resize($tablet) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    height: 100%;
    margin: 0 0 8px;
    padding: 16px 0 0;
    border-top: 1px solid rgba(255, 255, 255, 0.16);
  }
}

.sliderItemHeader {
  display: inline;

  margin: 0 12px 8px 0;
}

.sliderItemHeaderLink {
  display: inline-block;

  padding: 4px 0 0;

  @include on-res($mobile) {
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
  }
}

.sliderItemInfo {
  align-items: center;

  @include on-min-resize($tablet) {
    height: 20px;
  }
}

.sliderItemInfoItem {
  color: rgba(255, 255, 255, 0.6);
}

.sliderItemInfoBtn {
  @include on-res($mobile) {
    margin-top: -8px;
  }
}

.mediaContent {
  height: 100%;
  max-height: 400px;

  color: $color-surface;

  @include on-min-resize($tablet) {
    min-height: 400px;
  }
}
</style>
