<template>
  <div v-if="!dataFetching" data-vr-zone="Витрина новостей" :class="$style.windowBlock">
    <jtn-ui-typography
      v-if="pageTitle"
      :class="$style.windowBlockTitle"
      tag="h2"
      type="font-vijet-title"
    >
      {{ pageTitle }}
    </jtn-ui-typography>
    <div
      :class="[
        $style.container,
        hasSupercover ? $style.withoutPrimaryRecord : '',
        isNewRecordDesign ? $style.newDesign : ''
      ]"
    >
      <template v-for="(item, index) of windowBlockData">
        <div
          v-observe-visibility="obsArticlesHalfOptions(item)"
          v-if="isNewRecordBlock(item)"
          :key="`${item.id}_${index}`"
          :class="[
            $style.primaryRecord,
            item.isMain ? $style['main'] : '',
            item.types.desktop ? $style['_desktop'] : '',
            item.types.laptop ? $style['_laptop'] : '',
            item.types.tablet ? $style['_tablet'] : '',
            item.types.mobile ? $style['_mobile'] : ''
          ]"
        >
          <jtn-ui-main-record-item
            :is-main="item.isMain"
            :url="item.urls.url"
            :targetUrl="item.targetUrl"
            :header="entityDecode(item.header)"
            :subheader="item.subheader"
            :theme="item.theme"
            :rubric="item.rubric"
            :format="item.format"
            :data-vr-contentbox="getContentboxText(index)"
            :data-vr-contentbox-url="item.urls.urlCanonical"
            :is-active-translation="item.isActiveTranslation"
            :has-video-mark="
              item.hasVideoMark && !(item.imageData && item.imageData.hasCommercialLabel)
            "
            :is-mobile-only="isMobileOnly"
            @recordLinkClicked="articlesLinkClick(item, 'Заголовок')"
            @recordImageClicked="articlesLinkClick(item, 'Фото', 'Изображение')"
            @themeLinkClicked="formatOrRubricLinkClick($event, item, 'Сюжет')"
            @formatLinkClicked="formatOrRubricLinkClick($event, item, 'Формат')"
            @rubricLinkClicked="formatOrRubricLinkClick($event, item, 'Рубрика')"
          >
            <jtn-ui-image
              :class="[$style.recordImg, 'responsive-image']"
              slot="image"
              :src="item.imageData.src"
              :sources="item.imageData.sources"
              :loading="item.imageData.loading"
              :decoding="item.imageData.decoding"
              :hasCommercialLabel="item.imageData.hasCommercialLabel"
              fetchPriority="high"
              :aspectRatio="item.imageData.aspectRatio"
              :alt="item.header"
            />
            <jtn-ui-record-online-label
              slot="onlineLabel"
              label="онлайн-трансляция"
              :is-new-design="isNewRecordDesign"
            />
            <window-record-stats
              slot="stats"
              :data="item"
              :is-new-design="isNewRecordDesign"
              :is-main="index === 0 && isNewRecordDesign && isMainPage"
              :has-tooltip="item.isMain"
              :is-in-bookmarks="checkIfInBookmarks(item.id)"
              @onCommentsClicked="
                sendReachGoalForShowcase(item, 'Переход на комментарии', 'Комментарии')
              "
              @clickDiscussLink="goToDiscussLink($event, item)"
              @clickedBookmark="changeBookmarkStatus(item)"
            />
          </jtn-ui-main-record-item>
        </div>
        <div
          v-observe-visibility="obsArticlesHalfOptions(item)"
          v-if="
            item.viewType === 'articles' && !hideMain(item) && !isNewRecordBlock(item)
          "
          :key="`${item.id}_${index}`"
          :class="[
            $style.primaryRecord,
            item.isMain ? $style['main'] : '',
            item.types.desktop ? $style['_desktop'] : '',
            item.types.laptop ? $style['_laptop'] : '',
            item.types.tablet ? $style['_tablet'] : '',
            item.types.mobile ? $style['_mobile'] : ''
          ]"
        >
          <jtn-ui-primary-record-item
            :is-main="item.isMain"
            :url="item.urls.url"
            :is-new-record-design="isNewRecordDesign"
            :targetUrl="item.targetUrl"
            :header="entityDecode(item.header)"
            :subheader="item.subheader"
            :theme="item.theme"
            :rubric="item.rubric"
            :format="item.format ? item.format.text : ''"
            :format-url="item.format ? item.format.url : ''"
            :data-vr-contentbox="getContentboxText(index)"
            :data-vr-contentbox-url="item.urls.urlCanonical"
            :is-active-translation="item.isActiveTranslation"
            :has-video-mark="
              item.hasVideoMark && !(item.imageData && item.imageData.hasCommercialLabel)
            "
            :is-mobile-only="isMobileOnly"
            @recordLinkClicked="articlesLinkClick(item, 'Заголовок')"
            @recordImageClicked="articlesLinkClick(item, 'Фото', 'Изображение')"
            @themeLinkClicked="formatOrRubricLinkClick($event, item, 'Сюжет')"
            @formatLinkClicked="formatOrRubricLinkClick($event, item, 'Формат')"
            @rubricLinkClicked="formatOrRubricLinkClick($event, item, 'Рубрика')"
          >
            <div v-if="item.windowVideo" :class="$style.windowVideo" slot="image">
              <JtnUiPrimaryLoader />
              <Viqeo
                @firstPlayed="sendWindowVideoPlayedReachGoal(item)"
                autoplay-strategy="custom"
                :class="$style.windowVideoInner"
                :params="{
                  profileId: item.windowVideo.value.profileId,
                  videoId: item.windowVideo.value.videoId
                }"
              />
            </div>
            <jtn-ui-image
              v-else
              :class="[$style.recordImg, 'responsive-image']"
              slot="image"
              :src="item.imageData.src"
              :sources="item.imageData.sources"
              :loading="item.imageData.loading"
              :decoding="item.imageData.decoding"
              :has-commercial-label="item.imageData.hasCommercialLabel"
              :fetchPriority="item.isMain ? 'high' : 'auto'"
              :aspectRatio="item.imageData.aspectRatio"
              :alt="item.header"
            />
            <jtn-ui-record-online-label
              slot="onlineLabel"
              label="онлайн-трансляция"
              :is-new-design="isNewRecordDesign"
            />
            <window-record-stats
              slot="stats"
              :data="item"
              :is-new-design="isNewRecordDesign"
              :has-tooltip="item.isMain"
              :is-main="isNewRecordBlock(item)"
              :is-in-bookmarks="checkIfInBookmarks(item.id)"
              @onCommentsClicked="
                sendReachGoalForShowcase(item, 'Переход на комментарии', 'Комментарии')
              "
              @clickDiscussLink="goToDiscussLink($event, item)"
              @clickedBookmark="changeBookmarkStatus(item)"
            />
          </jtn-ui-primary-record-item>
        </div>
        <div
          v-observe-visibility="obsNewsHalfOptions(item)"
          v-if="item.viewType === 'news'"
          :key="`${item.id}_${index}`"
          :class="[
            $style.secondaryRecord,
            item.types.tablet ? $style['_tablet'] : '',
            item.types.mobile ? $style['_mobile'] : ''
          ]"
        >
          <jtn-ui-secondary-record-item
            :url="item.urls.url"
            :target="item.targetUrl"
            :header="item.header"
            :has-video-mark="item.hasVideoMark"
            :is-new-record-design="isNewRecordDesign"
            :has-commercial-label="item.hasCommercialLabel"
            record-type="big"
            :old-design="false"
            data-vr-contentbox="Новость без картинки в витрине"
            :data-vr-contentbox-url="item.urls.urlCanonical"
            :is-active-translation="item.isActiveTranslation"
            @linkClicked="newsLinkClicked(item)"
            @rubricClicked="sendNewsFeedRubricReachGoal($event, item)"
          >
            <jtn-ui-record-online-label
              v-if="isMobileOnly"
              slot="online-label"
              bgcolor="transparent"
            />
            <window-record-stats
              slot="stats"
              :data="item"
              :is-new-design="isNewRecordDesign"
              :is-main="index === 0 && isNewRecordDesign && isMainPage"
              :is-in-bookmarks="checkIfInBookmarks(item.id)"
              @clickedBookmark="changeBookmarkStatus(item, (isNewsFeed = true))"
              @clickDiscussLink="goToDiscussLink($event, item, (isNewsFeed = true))"
              @onCommentsClicked="sendNewsFeedCommentsReachGoal(item)"
            />
          </jtn-ui-secondary-record-item>
        </div>
        <component
          v-if="isShowAdvert(item.type) && isShowAdvertTablet(item)"
          v-bind="bindAdvComponent"
          :key="advKey(item)"
          :adv="item"
        />
        <auto-block
          v-if="item.type === 'avito_auto'"
          :key="`${item.type}_${index}`"
          :class="getCustomClasses({ ...item.settings.types, _auto: true })"
          :devices="getDevices(item)"
          position="window-block"
          type="Avito"
        />
        <forums-block
          v-if="item.type === 'forum'"
          :key="`${item.id}_${index}`"
          :class="getCustomClasses({ ...item.settings.types, _forum: true })"
        />

        <WidgetsBar
          v-if="index === 0 && isShowNewInformers"
          :class="[$style.widgetsBar]"
        />
      </template>
    </div>
    <div v-if="windowBlockBtn" :class="$style.stickyBtnBlock">
      <div
        :class="[$style.stickyBtnWrap, isStickyBtn ? $style['_sticky'] : '']"
        data-sticky-id="bottomStickyElement"
        :data-sticked="isStickyBtn"
      >
        <jtn-ui-btn
          :class="$style.stickyMoreBtn"
          :href="windowBlockBtn.link"
          @click="btnClick()"
        >
          {{ windowBlockBtn.buttonText }}
        </jtn-ui-btn>
      </div>
    </div>

    <bookmark-error-modal
      v-if="isBookmarkErrorModalRendered"
      v-model="isBookmarkErrorModalOpened"
    />
    <BlockedUserModal
      v-if="isBlockedUserModalRendered"
      v-model="isBlockedUserModalOpened"
    />

    <something-wrong-modal
      v-if="isSomethingWrongModalRendered"
      v-model="isSomethingWrongModalOpen"
    />

    <auth-and-register-modal v-if="isAuthModalRendered" v-model="isAuthModalOpen" />
  </div>
</template>

<script>
import { PagesType } from '@jtnews/shared/data';
import { JtnUiRecordOnlineLabel } from '@jtn-ui/components/record';
import {
  JtnUiBtn,
  JtnUiTypography,
  JtnUiPrimaryRecordItem,
  JtnUiSecondaryRecordItem,
  JtnUiMainRecordItem,
  JtnUiPrimaryLoader,
  JtnUiImage
} from '@jtnews/jtn-ui';
import { guardUnspecified } from '@portal/utils/util-guards';
import { Viqeo } from '../viqeo';

import { getPageYOffset, getElementOffset } from '@jtnews/shared/scroll-and-position';
import { getBreakpointByWindowWidth } from '@jtnews/shared';
import { RecordItemUtilsMixin, StoreMixin, EntityDecoderMixin } from '../../mixins';
import { WidgetsBar } from '../../../layout/containers/widgets-bar';

import WindowRecordStats from '../window-record-stats';
import { AutoBlock } from '../auto-block';
import { ForumsBlock } from '../forums-block';
import { Adv, AdvCreative } from '../_adv';

// eslint-disable-next-line @typescript-eslint/naming-convention
const BookmarkErrorModal = () =>
  import(/* webpackChunkName: "bookmark-error-modal" */ '@jtnews/layout/modals').then(
    m => m.BookmarkErrorModal
  );

const BlockedUserModal = () =>
  import(/* webpackChunkName: "blocked-user-modal" */ '@jtnews/layout/modals').then(
    m => m.BlockedUserModal
  );

// eslint-disable-next-line @typescript-eslint/naming-convention
const SomethingWrongModal = () =>
  import(/* webpackChunkName: "something-wrong-modal" */ '@jtnews/layout/modals').then(
    m => m.SomethingWrongModal
  );

// eslint-disable-next-line @typescript-eslint/naming-convention
const AuthAndRegisterModal = () =>
  import(/* webpackChunkName: "auth-and-register-modal" */ '@jtnews/layout/modals').then(
    m => m.AuthAndRegisterModal
  );

const NEWS_FEED_BLOCK_SIZE = 3;

export default {
  name: 'WindowBlock',
  components: {
    AutoBlock,
    ForumsBlock,
    JtnUiTypography,
    JtnUiBtn,
    JtnUiMainRecordItem,
    JtnUiPrimaryRecordItem,
    JtnUiSecondaryRecordItem,
    JtnUiPrimaryLoader,
    JtnUiImage,
    Adv,
    AdvCreative,
    WindowRecordStats,
    JtnUiRecordOnlineLabel,
    Viqeo,
    AuthAndRegisterModal,
    BookmarkErrorModal,
    BlockedUserModal,
    SomethingWrongModal,
    WidgetsBar
  },
  mixins: [RecordItemUtilsMixin, EntityDecoderMixin, StoreMixin],
  data() {
    return {
      isStickyBtn: false,
      currentPageOffset: 0,
      currentWindowHeight: 0,
      newsList: {},
      newsItems: [],
      articlesFieldType: 'Витрина',
      newsFieldType: 'Лента новостей',
      isBookmarkProcessing: false,
      isBookmarkErrorModalOpened: false,
      isBookmarkErrorModalRendered: false,
      isBlockedUserModalOpened: false,
      isBlockedUserModalRendered: false,
      isSomethingWrongModalOpen: false,
      isSomethingWrongModalRendered: false,
      isAuthModalOpen: false,
      isAuthModalRendered: false
    };
  },
  computed: {
    pageType() {
      return this.store.pageType;
    },
    isMainPage() {
      return this.pageType === PagesType.Main;
    },
    regionId() {
      return this.store.regionId;
    },
    isSafari() {
      return this.store.deviceInfo.isSafari;
    },
    isMobile() {
      return this.store.deviceInfo.isMobile;
    },
    isTablet() {
      return this.store.deviceInfo.isTablet;
    },
    isMobileOnly() {
      return this.isMobile && !this.isTablet;
    },
    dataFetching() {
      return this.store.dataFetching;
    },
    isAdblock() {
      return this.store.isAdblock;
    },
    hasSupercover() {
      return !!this.store.layoutModule?.supercover;
    },
    windowBlock() {
      return this.store.layoutModule.windowBlock;
    },
    isBlockedUser() {
      return this.store.commonModule.isBlockedUser;
    },
    userInfo() {
      return this.store.commonModule.userInfo || null;
    },
    windowBlockData() {
      return this.windowBlock.data;
    },
    windowBlockBtn() {
      return this.windowBlock.button;
    },
    isNewRecordDesign() {
      return this.store.layoutModule.isNewRecordDesign;
    },
    windowBlockParams() {
      return this.windowBlock.params;
    },
    pageTitle() {
      if (typeof this.windowBlockParams !== 'undefined') {
        return this.windowBlockParams.title || '';
      }

      return '';
    },
    isBookmarkError() {
      return this.store.userModule.isBookmarkLimitError;
    },
    isShowNewInformers() {
      return this.isMobileOnly && this.store.commonModule.sliderAbcTest !== 'A';
    },
    isScroogeTest() {
      return this.store.layoutModule.isScroogeTest;
    },
    bindAdvComponent() {
      return {
        is: this.isScroogeTest ? 'AdvCreative' : 'Adv'
      };
    }
  },
  mounted() {
    this.changeEventListener(true);
    this.calculateRecordItemIndex();
  },
  beforeDestroy() {
    this.changeEventListener(false);
  },
  updated() {
    this.newsList = {};
    this.newsItems = [];
    this.calculateRecordItemIndex();
  },
  methods: {
    isNewRecordBlock(item) {
      return (
        item.viewType === 'articles' &&
        this.isNewRecordDesign &&
        !this.hasSupercover &&
        this.isMainPage &&
        item.isMain &&
        guardUnspecified(item.imageData)
      );
    },
    getDevices(block) {
      return Object.entries(block.settings.types).reduce((acc, [key, value]) => {
        if (value) {
          acc.push(key);
        }
        return acc;
      }, []);
    },
    advKey(item) {
      return item.settings.placeId;
    },
    getArticleType(value) {
      return value ? 'Рекламный' : 'Редакционный';
    },
    getContentboxText(index) {
      return index === 0 ? 'Главная новость' : 'Новость с картинкой в витрине';
    },
    getCustomClasses(classes) {
      return {
        ...classes
      };
    },
    getNewsFeedRecordPlace(recordId) {
      return this.newsList[recordId] ? this.newsList[recordId] : 0;
    },
    getNewsFeedPlaceParams(currentPlace) {
      const firstArticlePlace =
        currentPlace - (currentPlace % NEWS_FEED_BLOCK_SIZE || NEWS_FEED_BLOCK_SIZE) + 1;
      const lastArticlePlace = firstArticlePlace + NEWS_FEED_BLOCK_SIZE - 1;

      return {
        block: `${firstArticlePlace}-${lastArticlePlace}`,
        ids: this.newsItems
          .slice(firstArticlePlace - 1, lastArticlePlace)
          .map(item => item.id)
          .join(',')
      };
    },
    hideMain(item) {
      return item.isMain && this.hasSupercover;
    },
    isShowAdvert(type) {
      return (
        type === 'adv' ||
        type === 'window_advert' ||
        type === 'mobile_record_inread_advert'
      );
    },
    isPictureRedesign() {
      return this.store.commonModule.isPictureRedesign;
    },
    // Костыль для АБ REGIONNEWS-34110 убирается задваивание рекламы на первом экране
    isShowAdvertTablet(block) {
      if (this.isPictureRedesign && block.settings.placeId === 10001) {
        const isMobileAdv = block.settings.types.mobile ?? false;
        const isTabletAdv = block.settings.types.tablet ?? false;
        return (isMobileAdv && this.isMobileOnly) || (isTabletAdv && this.isTablet);
      }
      return true;
    },
    changeEventListener(value) {
      if (this.isMobile && document.documentElement.clientWidth < 900) {
        if (value) {
          window.addEventListener('scroll', this.onScroll);
        } else {
          window.removeEventListener('scroll', this.onScroll, false);
        }
      }
    },
    onScroll() {
      const elTopCoords = getElementOffset(this.$el).bottom;
      const pageYOffset = getPageYOffset();
      const heightScreen = window.innerHeight;

      if (this.isStickyBtn) {
        this.isStickyBtn = elTopCoords - heightScreen >= pageYOffset;
      } else {
        this.isStickyBtn =
          elTopCoords - heightScreen >= pageYOffset &&
          this.isTopScroll(pageYOffset, heightScreen);

        this.currentPageOffset = pageYOffset <= 0 ? 0 : pageYOffset;
        this.currentWindowHeight = heightScreen;
      }
    },
    isTopScroll(pageYOffset, windowHeight) {
      // хак для ios safari при автоматическом уменьшении окна браузера
      if (this.isSafari && windowHeight < this.currentWindowHeight) {
        return true;
      }

      return pageYOffset < this.currentPageOffset;
    },
    calculateRecordItemIndex() {
      let newsIndex = 1;
      const deviceType = getBreakpointByWindowWidth(window.innerWidth);

      if (window.innerWidth < 900) {
        this.windowBlockData.forEach(item => {
          if (item.viewType === 'news' && item.types[deviceType]) {
            this.newsList[item.id] = newsIndex;
            this.newsItems.push(item);
            newsIndex++;
          }
        });
      }
    },
    obsArticlesHalfOptions(item) {
      return {
        callback: (isVisible, entry) =>
          this.checkArticlesBlockVisible(isVisible, entry, item),
        once: true,
        intersection: {
          threshold: 0.5
        }
      };
    },
    obsNewsHalfOptions(item) {
      return {
        callback: (isVisible, entry) =>
          this.checkNewsBlockVisible(isVisible, entry, item),
        once: true,
        intersection: {
          threshold: 0.5
        }
      };
    },
    checkArticlesBlockVisible(isVisible, entry, item) {
      if (isVisible) {
        const articleType = this.getArticleType(item.isCommercial);
        const place = item.position || 0;

        const value = {
          [place]: {
            Просмотр: {
              [item.id]: articleType
            }
          }
        };

        this.sendNewReachGoal(this.articlesFieldType, value, 'viewShowcase');
        this.sendNewFormatReachGoal({
          goalName: 'ViewShowcase',
          blockType: `Элемент витрины ${place}`,
          actionType: 'Просмотр',
          prop1: item.id
        });
      }
    },
    sendWindowVideoPlayedReachGoal(item) {
      const articleType = this.getArticleType(item.isCommercial);
      const place = item.position ?? 0;

      const value = {
        [place]: {
          'Запуск видео': {
            [item.id]: articleType
          }
        }
      };

      this.sendNewReachGoal(this.articlesFieldType, value, 'playShowcase');
    },
    checkNewsBlockVisible(isVisible, entry, item) {
      if (isVisible) {
        const place = this.newsList[item.id] ? this.newsList[item.id] : 0;
        const articleType = this.getArticleType(item.isCommercial);

        const value = {
          [place]: {
            Просмотр: {
              [articleType]: item.id
            }
          }
        };

        this.sendNewReachGoal(this.newsFieldType, value, 'viewNewsFeed');

        if (place % NEWS_FEED_BLOCK_SIZE !== 0) {
          return;
        }

        const { block, ids } = this.getNewsFeedPlaceParams(place);

        this.sendNewFormatReachGoal({
          goalName: `ViewNewsline${block}`,
          blockType: `Блок ленты ${block}`,
          actionType: 'Просмотр',
          prop1: ids
        });
      }
    },
    formatOrRubricLinkClick({ linkName }, item, linkType) {
      this.sendReachGoalForShowcase(
        item,
        'Переход на тему/формат/рубрику',
        `${linkType} ${linkName}`
      );
    },
    btnClick() {
      this.sendNewReachGoal(this.articlesFieldType, 'Все новости', 'clickShowcase');
      this.sendNewFormatReachGoal({
        goalName: 'ClickShowcase',
        blockType: 'Элемент витрины',
        actionType: 'Клик',
        prop1: 'Все новости'
      });
      if (window.innerWidth < 900) {
        this.sendNewReachGoal(this.newsFieldType, 'Все новости', 'clickNewsFeed');
      }
    },
    goToDiscussLink({ event }, item, isNewsFeed = false) {
      if (this.isBlockedUser === true) {
        event.preventDefault();
        this.openBlockedUserModal();
      } else if (isNewsFeed) {
        this.sendNewsFeedCommentsReachGoal(item);
      } else {
        this.sendReachGoalForShowcase(item, 'Переход на комментарии', 'Комментарии');
      }
    },
    openBlockedUserModal() {
      this.isBlockedUserModalOpened = true;
      this.isBlockedUserModalRendered = true;
    },
    checkIfInBookmarks(recordId) {
      return this.store.userModule.recordsWithBookmark.includes(recordId);
    },
    async changeBookmarkStatus(record, isNewsFeed = false) {
      if (this.isBookmarkProcessing === true) {
        return;
      }

      const bookmarkId = this.store.userModule.bookmarkIdsByRecord[record.id] || '';

      if (this.userInfo === null) {
        this.sendBookmarkReachGoal(record, bookmarkId, isNewsFeed, false);
        this.showAuthModal();
        return;
      }

      this.isBookmarkProcessing = true;

      try {
        await this.store.userModule.changeBookmarkStatus({
          regionId: this.regionId,
          profileId: this.userInfo.userId,
          recordId: record.id,
          bookmarkId,
          type: 'record'
        });

        this.sendBookmarkReachGoal(record, bookmarkId, isNewsFeed);
      } catch (error) {
        this.openModal();
      } finally {
        this.isBookmarkProcessing = false;
      }
    },
    openModal() {
      if (this.isBookmarkError) {
        this.openBookmarkErrorModal();
        return;
      }

      this.openErrorModal();
    },
    openBookmarkErrorModal() {
      this.isBookmarkErrorModalOpened = true;
      this.isBookmarkErrorModalRendered = true;
    },
    openErrorModal() {
      this.isSomethingWrongModalOpen = true;
      this.isSomethingWrongModalRendered = true;
    },
    showAuthModal() {
      this.isAuthModalRendered = true;
      this.isAuthModalOpen = true;
    },
    sendBookmarkReachGoal(record, bookmarkId, isNewsFeed, isAuthorized = true) {
      const fieldType = isNewsFeed ? this.newsFieldType : this.articlesFieldType;
      const place = isNewsFeed
        ? this.getNewsFeedRecordPlace(record.id)
        : record.position || 0;
      const { block } = this.getNewsFeedPlaceParams(place);
      const articleType = this.getArticleType(record.isCommercial);
      const prop2 =
        bookmarkId !== '' ? 'Удалил из избранного' : 'Успешно добавил в избранное';

      this.store.userModule.sendBookmarkReachGoal({
        recordId: record.id,
        bookmarkId,
        showcaseParams: {
          place,
          fieldType,
          articleType
        },
        isAuthorized,
        willDeprecate: true
      });

      this.sendNewFormatReachGoal({
        goalName: isNewsFeed ? `ClickNewsline${block}` : 'ClickShowcase',
        blockType: isNewsFeed ? `Блок ленты ${block}` : `Элемент витрины ${place}`,
        actionType: 'Клик',
        prop1: record.id,
        prop2: isAuthorized === false ? 'Добавить в избранное' : prop2
      });
    },
    sendNewsFeedCommentsReachGoal(item) {
      const place = this.getNewsFeedRecordPlace(item.id);
      const articleType = this.getArticleType(item.isCommercial);

      const value = {
        [place]: {
          'Переход на комментарии': {
            [articleType]: item.id
          }
        }
      };

      const { block } = this.getNewsFeedPlaceParams(place);

      this.sendNewReachGoal(this.newsFieldType, value, 'clickNewsFeed');
      this.sendNewFormatReachGoal({
        goalName: `ClickNewsline${block}`,
        blockType: `Блок ленты ${block}`,
        actionType: 'Клик',
        prop1: item.id,
        prop2: 'Комментарии'
      });
    },
    sendNewsFeedRubricReachGoal({ rubricName }, item) {
      const place = this.getNewsFeedRecordPlace(item.id);
      const articleType = this.getArticleType(item.isCommercial);

      const value = {
        [place]: {
          [`Переход на рубрику ${rubricName}`]: {
            [articleType]: item.id
          }
        }
      };

      const { block } = this.getNewsFeedPlaceParams(place);

      this.sendNewReachGoal(this.newsFieldType, value, 'clickNewsFeed');
      this.sendNewFormatReachGoal({
        goalName: `ClickNewsline${block}`,
        blockType: `Блок ленты ${block}`,
        actionType: 'Клик',
        prop1: item.id,
        prop2: `Рубрика ${rubricName}`
      });
    },
    articlesLinkClick(item, target, targetNewFormat) {
      const articleType = this.getArticleType(item.isCommercial);
      const place = item.position || 0;

      const value = {
        [place]: {
          'Переход на материал': {
            [item.id]: {
              [articleType]: target
            }
          }
        }
      };

      this.sendNewReachGoal(this.articlesFieldType, value, 'clickShowcase');
      this.sendNewFormatReachGoal({
        goalName: 'ClickShowcase',
        blockType: `Элемент витрины ${place}`,
        actionType: 'Клик',
        prop1: item.id,
        prop2: targetNewFormat ? targetNewFormat : target
      });
    },
    newsLinkClicked(item) {
      const place = this.getNewsFeedRecordPlace(item.id);
      const articleType = this.getArticleType(item.isCommercial);

      const value = {
        [place]: {
          'Переход на материал': {
            [articleType]: item.id
          }
        }
      };

      const { block } = this.getNewsFeedPlaceParams(place);

      this.sendNewReachGoal(this.newsFieldType, value, 'clickNewsFeed');
      this.sendNewFormatReachGoal({
        goalName: `ClickNewsline${block}`,
        blockType: `Блок ленты ${block}`,
        actionType: 'Клик',
        prop1: item.id,
        prop2: 'Заголовок'
      });
    },
    sendReachGoalForShowcase(item, action, propValue) {
      const place = item.position || 0;
      const articleType = this.getArticleType(item.isCommercial);

      const value = {
        [place]: {
          [action]: {
            [item.id]: articleType
          }
        }
      };

      this.sendNewReachGoal(this.articlesFieldType, value, 'clickShowcase');
      this.sendNewFormatReachGoal({
        goalName: 'ClickShowcase',
        blockType: `Элемент витрины ${place}`,
        actionType: 'Клик',
        prop1: item.id,
        prop2: propValue
      });
    },
    sendNewReachGoal(fieldType, valueName, goalName) {
      const blockType = 'Центральная колонка';

      this.store.analyticsModule.sendNewReachGoal({
        blockType,
        fieldType,
        valueName,
        goalName,
        willDeprecate: true
      });
    },
    sendNewFormatReachGoal({ goalName, blockType, actionType, prop1, prop2 }) {
      this.store.analyticsModule.sendNewFormatReachGoal({
        goalName,
        blockType,
        actionType,
        prop1,
        prop2
      });
    }
  }
};
</script>
<style lang="scss" module>
@use '~jtn-ui/z-index';

@import 'styles';

.windowBlock {
  :global .jtn-widget-adv {
    padding: 0;
    margin-bottom: $desk-bottom-indent;

    @include on-res($tablet) {
      width: 100%;
      padding: 0;
      margin-bottom: 0;
      background: $color-surface-200;

      & .adv-container > div {
        padding: 8px 0;
      }
    }
  }

  :global ._auto {
    display: none;
    border-top: 1px solid $color-surface-200;
    @include on-min-resize($tablet) {
      border-bottom: 8px solid $color-surface-200;
    }

    @include on-min-resize($laptop) {
      padding: 0 $desktop-padding;
      margin: 0 -#{$desktop-padding} 16px;
      border-bottom: 1px solid $color-surface-200;
    }

    @include on-min-resize($desktop) {
      border-bottom: none;
    }
  }

  :global ._forum {
    display: none;

    @include on-min-resize($laptop) {
      width: calc(100% + #{$desktop-padding * 2});
      margin: 0 -#{$desktop-padding} 0;
    }

    @include on-min-resize($desktop) {
      border-top: 1px solid $color-surface-200;
      border-bottom: none;
    }
  }
}

.windowBlockTitle {
  padding: 12px $mobile-padding;

  @include on-min-resize($tablet) {
    padding: 14px $desktop-padding;
  }

  @include on-min-resize($laptop) {
    display: none;
  }
}

.primaryRecord {
  display: none;
  width: 100%;
  margin: 0;
  @include on-min-resize($laptop) {
    width: calc(50% - 11px);

    &.main {
      width: 100%;
    }
  }

  .recordImg {
    width: 100%;
  }

  :global(.global-wrapper:not(._branding)) & {
    &._laptop {
      @include on-min-resize($desktop) {
        display: none;
      }
    }

    @include on-min-resize($desktop) {
      width: calc(50% - 15px);

      &.main {
        width: calc(100% + #{$desktop-padding * 2});
      }

      &._desktop {
        display: block;
      }
    }
  }

  &._desktop {
    @include on-res($desktop) {
      display: none;
    }

    @include on-res($tablet) {
      display: none;
    }

    @include on-res($mobile) {
      display: none;
    }
  }

  &._laptop {
    @include on-min-resize($laptop) {
      display: block;
    }

    @include on-res($tablet) {
      display: none;
    }

    @include on-res($mobile) {
      display: none;
    }
  }

  &._tablet {
    @include on-res($tablet) {
      display: block;
    }

    @include on-res($mobile) {
      display: none;
    }
  }

  &._mobile {
    @include on-res($mobile) {
      display: block;
    }
  }

  @include on-not-mobile-width-and-hover {
    :global(.windowStatsBookmark) {
      opacity: 0;
      visibility: hidden;
    }

    &:hover {
      :global(.windowStatsBookmark) {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}

.secondaryRecord {
  display: none;
  width: 100%;

  &._tablet {
    @include on-res($tablet) {
      display: block;
    }

    @include on-res($mobile) {
      display: none;
    }
  }

  &._mobile {
    @include on-res($mobile) {
      display: block;
      padding: 12px 0;
      border-top: 1px solid $color-surface-100;
    }
  }

  @include on-not-mobile-width-and-hover {
    :global(.windowStatsBookmark) {
      opacity: 0;
      visibility: hidden;
    }

    &:hover {
      :global(.windowStatsBookmark) {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}

.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  &.newDesign {
    margin-top: 8px;
    row-gap: 8px;
    @include on-min-resize($laptop) {
      row-gap: 0;
    }
  }

  @include on-min-resize($laptop) {
    &.withoutPrimaryRecord {
      padding-top: $desktop-padding;
    }
  }

  .widgetsBar {
    margin: 0 $central-column-mobile-padding-negative;
    width: 100vw;
  }
}

.stickyBtnBlock {
  height: 72px;

  @include on-min-resize($tablet) {
    height: 88px;
  }

  @include on-min-resize($laptop) {
    height: 68px;
  }
}

.stickyBtnWrap {
  width: calc(100% - #{$mobile-side-indent * 2});
  padding: $mobile-side-indent $mobile-side-indent
    calc($text-mobile-padding + $mobile-tab-bar-safe-area);
  transition: none;
  background-color: $color-surface;

  @include on-min-resize($tablet) {
    width: calc(100% - 64px);
    margin: $desktop-padding;
  }

  @include on-min-resize($laptop) {
    display: block;
    width: 320px;
    margin: 0 auto 20px;
  }

  &._sticky {
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: z-index.$fixed;

    transform: translate3d(0, 0, 0);
    transition: transform 0.3s;

    @include on-min-resize(tablet) {
      padding: $desktop-padding 40px;
      margin: 0;
    }
  }
}

.stickyMoreBtn {
  width: 100%;
}

.windowVideo {
  position: relative;
  width: 100%;
  height: auto;
  padding-bottom: 56.25%;
  overflow: hidden;
  background: $color-surface-100;

  .windowVideoInner {
    width: 100%;
    bottom: 0;
    left: 0;
    position: absolute;
    z-index: z-index.$above;
  }
}
</style>
