import { isAbsoluteUrl } from '@portal/utils/util-url';
import { componentFactory } from 'vue-tsx-support';

import {
  JtnUiBaseBlock,
  JtnUiTopRecordsItem,
  JtnUiImage,
  AspectRatio
} from '@jtnews/jtn-ui';
import {
  injectStylesMixin,
  tsStoreMixin,
  RecordItemUtilsMixin,
  ObserverVisibility,
  EntityDecoderMixin
} from '@jtnews/shared';
import { addUrlParams } from '@jtnews/shared/news';

import { NetworkContentItem } from '../../../layout/domain';

import styles from './widget-network-content.styles.scss?module';

// eslint-disable-next-line @typescript-eslint/naming-convention
const BlockedUserModal = () =>
  import(/* webpackChunkName: "blocked-user-modal" */ '@jtnews/layout/modals').then(
    m => m.BlockedUserModal
  );

type NewFormatReachGoal = {
  goalName: string;
  actionType: string;
  prop1: string;
  prop2?: string;
};

type ReachGoalValues = string | Record<string, string>;

type ComponentData = {
  lazyLoadObsVisibility: ObserverVisibility;
  halfObsVisibility: ObserverVisibility;
  title: string;
  recordsList: NetworkContentItem[];
  isBlockedUserModalOpened: boolean;
  isBlockedUserModalRendered: boolean;
};

const NETWORKS_LIST_LINK = '/text/weekend/';

export default componentFactory
  .mixin(injectStylesMixin(styles))
  .mixin(tsStoreMixin)
  .mixin(EntityDecoderMixin)
  .mixin(RecordItemUtilsMixin)
  .create({
    name: 'WidgetNetworkContent',
    data(): ComponentData {
      return {
        lazyLoadObsVisibility: new ObserverVisibility('lazyLoad'),
        halfObsVisibility: new ObserverVisibility('half'),
        title: '',
        recordsList: [],
        isBlockedUserModalOpened: false,
        isBlockedUserModalRendered: false
      };
    },
    computed: {
      networkContentDataMapper() {
        return this.store.networkContentDataMapper;
      },
      regionId(): number {
        return this.store.regionId;
      },
      isBlockedUser(): boolean {
        return this.store.commonModule?.isBlockedUser;
      },
      isShowBlock() {
        return this.recordsList.length > 0 && this.title.length > 0;
      },
      isMobile(): boolean {
        return this.store.deviceInfo.isMobile && !this.store.deviceInfo.isTablet;
      }
    },
    mounted() {
      this.lazyLoadObsVisibility.event.subscribe((state: { value: boolean }) => {
        if (state.value) {
          this.fetchBlockData() as unknown as void;
        }
      });
      this.halfObsVisibility.event.subscribe((state: { value: boolean }) => {
        if (state.value) {
          this.sendNewReachGoal('Просмотр', 'ViewNetcontent');
          this.sendNewFormatReachGoal({
            goalName: 'ViewNetcontent',
            actionType: 'Просмотр',
            prop1: this.recordsList.map(item => item.id).join(', ')
          });
        }
      });
    },
    beforeDestroy() {
      this.lazyLoadObsVisibility.destroy();
      this.halfObsVisibility.destroy();
    },
    methods: {
      async fetchBlockData() {
        try {
          const blockData =
            await this.networkContentDataMapper?.fetchNetworkContentBlock();
          this.title = blockData?.title ?? '';
          this.recordsList = blockData?.items ?? [];
        } catch (err) {
          console.error(err);
          throw err;
        }
      },
      getDiscussUrl(url: string) {
        return addUrlParams(url, { discuss: '1' });
      },
      clickImage(id: number) {
        this.sendNewReachGoal(
          // eslint-disable-next-line @typescript-eslint/naming-convention
          { 'Переход на материал (фото)': id.toString() },
          'ClickNetcontent'
        );
        this.sendNewFormatReachGoal({
          goalName: 'ClickNetcontent',
          actionType: 'Клик',
          prop1: id.toString(),
          prop2: 'Картинка'
        });
      },
      clickedDiscussLink(
        event: Event,
        valueName: string,
        valueNameNewFormat: string,
        id: number
      ) {
        if (this.isBlockedUser) {
          event.preventDefault();
          this.openBlockedUserModal();
        } else {
          this.sendReachGoalForClickLink(valueName, valueNameNewFormat, id);
        }
      },
      sendReachGoalForClickLink(
        valueName: string,
        valueNameNewFormat: string,
        id: number
      ) {
        this.sendNewReachGoal({ [valueName]: id.toString() }, 'ClickNetcontent');
        this.sendNewFormatReachGoal({
          goalName: 'ClickNetcontent',
          actionType: 'Клик',
          prop1: id.toString(),
          prop2: valueNameNewFormat
        });
      },
      getTargetLink(path: string): '_blank' | '_self' {
        return isAbsoluteUrl(path) ? '_blank' : '_self';
      },
      sendNewReachGoal(valueName: ReachGoalValues, goalName: string) {
        const blockType = 'Центральная колонка';
        const fieldType = 'Сетевой контент';

        this.store.analyticsModule.sendNewReachGoal({
          blockType,
          fieldType,
          valueName,
          goalName,
          willDeprecate: true
        });
      },
      sendNewFormatReachGoal({ goalName, actionType, prop1, prop2 }: NewFormatReachGoal) {
        this.store.analyticsModule.sendNewFormatReachGoal({
          blockType: 'Сетевой контент',
          goalName,
          actionType,
          prop1,
          prop2
        });
      },
      openBlockedUserModal() {
        this.isBlockedUserModalOpened = true;
        this.isBlockedUserModalRendered = true;
      }
    },
    render() {
      return (
        <div
          v-observe-visibility={this.lazyLoadObsVisibility.getOptions('lazyLoad')}
          class={styles.widgetNetContent}
        >
          {this.isShowBlock && (
            <JtnUiBaseBlock
              v-observe-visibility={this.halfObsVisibility.getOptions('half')}
              header={this.title}
              url={NETWORKS_LIST_LINK}
            >
              <div class={styles.content}>
                {this.recordsList.map((item: NetworkContentItem, index: number) => (
                  <JtnUiTopRecordsItem
                    class={index === 0 ? styles.primaryRecord : styles.secondaryRecord}
                    commentsCount={item.commentsCount}
                    commentsUrl={item.urls.urlComments}
                    discussUrl={this.getDiscussUrl(item.urls.urlComments)}
                    header={this.entityDecode(item.header)}
                    isCommentsAllowed={item.isCommentsAllowed}
                    key={item.id}
                    url={item.urls.url}
                    viewsCount={item.viewsCount}
                    onRecordClicked={() =>
                      this.sendReachGoalForClickLink(
                        'Переход на материал (заголовок)',
                        'Заголовок',
                        item.id
                      )
                    }
                    onCommentsClicked={() =>
                      this.sendReachGoalForClickLink(
                        'Переход на комментарии',
                        'Комментарии',
                        item.id
                      )
                    }
                    onDiscussClicked={event =>
                      this.clickedDiscussLink(
                        event,
                        'Переход на комментарии',
                        'Комментарии',
                        item.id
                      )
                    }
                  >
                    {index === 0 && item.mainPhoto.url && (
                      <a
                        slot="image"
                        href={item.urls.url}
                        target="_blank"
                        onClick={() => this.clickImage(item.id)}
                      >
                        <JtnUiImage
                          class={styles.recordImageWrap}
                          aspectRatio={AspectRatio.None}
                          hasCommercialLabel={item.mainPhoto.hasCommercialLabel}
                          sources={item.mainPhoto.sources}
                          src={item.mainPhoto.img.src}
                        />
                      </a>
                    )}
                  </JtnUiTopRecordsItem>
                ))}
              </div>
            </JtnUiBaseBlock>
          )}
          {this.isBlockedUserModalRendered && (
            <BlockedUserModal vModel={this.isBlockedUserModalOpened} />
          )}
        </div>
      );
    }
  });
