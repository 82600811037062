import { guardEmptyString, guardUnspecified } from '@portal/utils/util-guards';
import { componentFactory } from 'vue-tsx-support';

import { JtnUiFeedbackBlock } from '@jtnews/jtn-ui';
import { injectStylesMixin, BaseBlockFunctionalityMixin } from '@jtnews/shared';

import { tsStoreMixin } from '../../mixins';

import styles from './feedback-block.styles.scss?module';

// eslint-disable-next-line @typescript-eslint/naming-convention
const FeedbackToEditorsModal = () =>
  import('@jtnews/layout/modals').then(m => m.FeedbackToEditorsModal);

const PIECE_PAGE = 'Контент';
const FIELD_TYPE = 'Форма связи с редакцией';

const MODAL_PARAMS = { piecePage: PIECE_PAGE };

type SocialLinks = {
  telegram: string;
  viber: string;
  vk: string;
  whatsapp: string;
  instagram: string;
};

type FeedbackInfo = {
  phone?: string;
  phoneText?: string;
  prePhoneLabel?: string;
  preSocialLinksLabel?: string;
  altPhone?: string;
  altPhoneText?: string;
  socialLinksDesktop?: SocialLinks;
  socialLinksMobile?: SocialLinks;
  text: string;
};

type FeedbackData = {
  params: {
    title?: string;
  };
  data?: FeedbackInfo;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  reach_goal: Record<string, unknown> | null;
};

type SocialData = {
  url: string;
  target?: string;
  title?: string;
  name: string;
  icon: string;
  width: string;
  height: string;
};

type NewFormatReachGoal = {
  goalName: string;
  actionType: string;
  prop1?: string;
};

const defaultFeedbackData: FeedbackData = {
  params: {},
  data: {
    text: ''
  },
  // eslint-disable-next-line @typescript-eslint/naming-convention
  reach_goal: null
};

const socials: SocialData[] = [
  {
    name: 'Telegram',
    icon: 'telegram',
    url: '',
    width: '26',
    height: '21'
  },
  {
    name: 'Viber',
    icon: 'viber',
    url: '',
    target: '_self',
    width: '28',
    height: '30'
  },
  {
    name: 'Группа вконтакте',
    icon: 'vk',
    url: '',
    width: '24px',
    height: '11px'
  },
  {
    name: 'Whatsapp',
    icon: 'whatsapp',
    url: '',
    width: '27',
    height: '27'
  },
  {
    name: 'Instagram',
    icon: 'instagram',
    url: '',
    width: '24',
    height: '24'
  },
  {
    name: 'Одноклассники',
    icon: 'odnoklassniki',
    url: '',
    width: '24',
    height: '24'
  }
];

export default componentFactory
  .mixin(injectStylesMixin(styles))
  .mixin(tsStoreMixin)
  .mixin(BaseBlockFunctionalityMixin)
  .create({
    name: 'FeedbackBlock',
    data() {
      return {
        blockData: defaultFeedbackData,
        isModalOpen: false
      };
    },
    computed: {
      info(): FeedbackInfo {
        return this.blockData.data || {};
      },
      text(): FeedbackInfo['text'] {
        return this.info.text || '';
      },
      phone(): FeedbackInfo['phone'] {
        return this.info.phone || '';
      },
      prePhoneLabel(): FeedbackInfo['prePhoneLabel'] {
        return this.info.prePhoneLabel || '';
      },
      preSocialLinksLabel(): FeedbackInfo['preSocialLinksLabel'] {
        return this.info.preSocialLinksLabel || '';
      },
      altPhone(): FeedbackInfo['altPhone'] {
        return this.info.altPhone || '';
      },
      socialsData(): SocialData[] {
        const socialLinks = this.isMobileDevices
          ? this.info.socialLinksMobile
          : this.info.socialLinksDesktop;

        const availableSocials = socials.filter((social: SocialData) => {
          const link = socialLinks[social.icon.toLowerCase()];
          return guardUnspecified(link) && guardEmptyString(link);
        });

        return availableSocials.map((social: SocialData) => {
          const socialName = social.icon.toLowerCase();
          const getViberTitle = () =>
            this.isMobileDevices ? '' : 'Должен быть установлен Viber для ПК';

          return {
            ...social,
            url: socialLinks[socialName],
            title: socialName === 'viber' ? getViberTitle() : social.title
          };
        });
      },
      phoneLink(): string {
        const value = this.phone;
        return value.replace(/\s/g, '');
      },
      canRender(): boolean {
        return this.info.text && this.componentVisible;
      },
      isMobileDevices(): boolean {
        return this.store.deviceInfo.isMobile;
      }
    },
    methods: {
      async componentShown() {
        try {
          const res = await this.newsApiClient.getEditorialOfficeBlock({
            regionId: this.regionId
          });
          this.blockData = res.data;

          this.sendReachGoalFromApi(this.$_BaseBlockFuncMixin_generalReachGoals);
          this.sendNewFormatReachGoal({
            goalName: 'ViewSendNews',
            actionType: 'Просмотр'
          });
        } catch (err) {
          console.error(err);
          throw err;
        }
      },
      onVisibleBlock() {
        this.sendNewReachGoal(PIECE_PAGE, FIELD_TYPE, 'Просмотр', 'viewContactEditors');
      },
      openForm() {
        this.sendNewReachGoal(PIECE_PAGE, FIELD_TYPE, 'Открыта', 'contactEditors');
        this.sendNewFormatReachGoal({
          goalName: 'ClickSendNews',
          actionType: 'Клик',
          prop1: 'Кнопка'
        });
        this.isModalOpen = true;
      },
      sendReachGoalMessenger(name: string) {
        this.sendNewReachGoal(PIECE_PAGE, FIELD_TYPE, name, 'contactEditorsMessenger');
        this.sendNewFormatReachGoal({
          goalName: 'ClickSendNews',
          actionType: 'Клик',
          prop1: name
        });
      },
      sendNewReachGoal(
        blockType: string,
        fieldType: string,
        valueName: string,
        goalName: string
      ) {
        this.store.analyticsModule.sendNewReachGoal({
          blockType,
          fieldType,
          valueName,
          goalName,
          willDeprecate: true
        });
      },
      sendNewFormatReachGoal({ goalName, actionType, prop1 }: NewFormatReachGoal) {
        this.store.analyticsModule.sendNewFormatReachGoal({
          blockType: 'Сообщить новость',
          goalName,
          actionType,
          prop1
        });
      }
    },
    render() {
      return (
        <div
          v-observe-visibility={this.obsVisibilityOptions}
          class={styles.feedbackBlock}
        >
          {this.canRender && (
            <JtnUiFeedbackBlock
              v-observe-visibility={this.obsHalfOptions}
              header={this.params.title}
              text={this.text}
              prePhoneLabel={this.prePhoneLabel}
              preSocialLinksLabel={this.preSocialLinksLabel}
              phone={this.phone}
              altPhone={this.altPhone}
              socials={this.socialsData}
              onClickBtn={this.openForm}
              onClickPhone={() => this.sendReachGoalMessenger('Телефон')}
              onClickSocial={(event: string) => this.sendReachGoalMessenger(event)}
            />
          )}

          <FeedbackToEditorsModal v-model={this.isModalOpen} params={MODAL_PARAMS} />
        </div>
      );
    }
  });
