import { componentFactoryOf } from 'vue-tsx-support';

import { injectStylesMixin } from '../../../mixins/inject-styles';

import styles from './jtn-ui-primary-record-item.styles.scss?module';

interface Events {
  onRecordImageClicked: Event;
  onRubricLinkClicked: Event;
  onThemeLinkClicked: { event: Event; linkName: string };
  onFormatLinkClicked: { event: Event; linkName: string };
  onRecordLinkClicked: { event: Event; linkName: string };
}

type RubricAndThemeType = {
  text: string;
  url: string;
};

export default componentFactoryOf<Events>()
  .mixin(injectStylesMixin(styles))
  .create({
    name: 'JtnUiPrimaryRecordItem',
    props: {
      isMain: {
        type: Boolean,
        default: false
      },
      place: {
        type: String,
        default: 'center'
      },
      url: {
        type: String,
        default: ''
      },
      header: {
        type: String,
        default: ''
      },
      subheader: {
        type: String,
        default: ''
      },
      theme: {
        type: Object as () => RubricAndThemeType | null,
        default: null
      },
      rubric: {
        type: Object as () => RubricAndThemeType | null,
        default: null
      },
      format: {
        type: String,
        default: ''
      },
      formatUrl: {
        type: String,
        default: ''
      },
      isActiveTranslation: {
        type: Boolean,
        default: false
      },
      isNewRecordDesign: {
        type: Boolean,
        default: false
      },
      targetUrl: {
        type: String as () => '_self' | '_blank',
        default: '_self'
      },
      isMobileOnly: {
        type: Boolean,
        default: false
      },
      hasVideoMark: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      hasFormatBlock(): boolean {
        return this.format.length > 0 && !this.isActiveTranslation;
      },
      isVideoMark(): boolean {
        return !this.isActiveTranslation && this.hasVideoMark;
      },
      markVideoSize(): number {
        return this.isNewRecordDesign ? 24 : this.isMobileOnly ? 32 : 20;
      }
    },
    render() {
      const attributes = {
        attrs: this.$attrs,
        on: this.$listeners
      };

      return (
        <article
          class={[
            styles.primaryRecordItem,
            styles[this.place],
            this.isMain ? styles.main : '',
            this.hasVideoMark ? styles.hasVideos : '',
            this.isNewRecordDesign ? styles.newDesign : ''
          ]}
          {...attributes}
        >
          <a
            class={[styles.imageWrap, !this.$slots.image ? styles.empty : '']}
            href={this.url}
            title={this.header}
            target={this.targetUrl}
            onClick={(event: Event) => {
              this.$emit('recordImageClicked', event);
            }}
          >
            {this.$slots.image}

            {this.isVideoMark && (
              <div class={styles.markVideo}>
                <svg width={this.markVideoSize} height={this.markVideoSize}>
                  <use xlinkHref="/dist/legacy/svg-sprites/jtn-critical.42b7545660e4f467e75d4b37a02533e6.svg#jtn-critical-video-outlined"></use>
                </svg>
              </div>
            )}
          </a>

          <div class={styles.infoWrap}>
            <div
              class={[
                styles.tags,
                this.isActiveTranslation ? styles.withOnlineLabel : ''
              ]}
            >
              {this.isActiveTranslation ? (
                <p class={styles.onlineLabel}>{this.$slots.onlineLabel}</p>
              ) : null}

              {this.rubric !== null ? (
                <p class={styles.rubric}>
                  <a
                    class={styles.rubricLink}
                    href={this.rubric?.url}
                    title={this.rubric?.text}
                    onClick={(event: Event) => {
                      this.$emit('rubricLinkClicked', {
                        event,
                        linkName: this.rubric?.text
                      });
                    }}
                  >
                    {this.rubric?.text}
                  </a>
                </p>
              ) : null}

              {this.theme !== null ? (
                <p class={styles.theme}>
                  <a
                    class={styles.themeLink}
                    href={this.theme?.url}
                    title={this.theme?.text}
                    onClick={(event: Event) => {
                      this.$emit('themeLinkClicked', {
                        event,
                        linkName: this.theme?.text
                      });
                    }}
                  >
                    {this.theme?.text}
                  </a>
                </p>
              ) : null}

              {this.hasFormatBlock ? (
                <p class={styles.format}>
                  {this.formatUrl ? (
                    <a
                      class={styles.formatLink}
                      href={this.formatUrl}
                      title={this.format}
                      onClick={(event: Event) => {
                        this.$emit('formatLinkClicked', { event, linkName: this.format });
                      }}
                    >
                      {this.format}
                    </a>
                  ) : (
                    <span>{this.format}</span>
                  )}
                </p>
              ) : null}
            </div>

            <h3 class={styles.header} data-vr-headline>
              <a
                class={styles.headerLink}
                href={this.url}
                title={this.header}
                target={this.targetUrl}
                onClick={(event: Event) => {
                  this.$emit('recordLinkClicked', event);
                }}
              >
                <span domPropsInnerHTML={this.header} />
              </a>
            </h3>

            {this.subheader.length > 0 ? (
              <p class={styles.subheader}>
                <a
                  class={styles.subheaderLink}
                  href={this.url}
                  title={this.header}
                  target={this.targetUrl}
                  onClick={(event: Event) => {
                    this.$emit('recordLinkClicked', event);
                  }}
                >
                  <span domPropsInnerHTML={this.subheader} />
                </a>
              </p>
            ) : null}

            <div class={styles.stats}>{this.$slots.stats}</div>
          </div>
        </article>
      );
    }
  });
