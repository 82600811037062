import { guardUnspecified } from '@portal/utils/util-guards';
import { componentFactory } from 'vue-tsx-support';

import {
  injectStylesMixin,
  tsStoreMixin,
  WidgetCurrencyRedesign as WidgetCurrency,
  WidgetTrafficRedesign as WidgetTraffic,
  WidgetWeatherRedesign as WidgetWeather
} from '@jtnews/shared';

import styles from './widgets-bar.styles.scss?module';

type CurrencyBlockData = {
  link: string;
  currency: {
    delta: number;
    name: string;
    nominal: number;
    value: number;
  }[];
};

type TrafficBlockData = {
  level: number;
  link: string;
};

type WeatherBlockData = {
  city: string;
  ['city_prepositional']: string;
  cloud: {
    title: string;
    value: string;
  };
  humidity: number;
  icon: string;
  more: string;
  precipitation: {
    title: string;
    value: string;
  };
  pressure: number;
  temperature: number;
  ['temperature_feel_like']: number;
  ['temperature_trend']: number;
  wind: {
    direction: {
      title: string;
      ['title_letter']: string;
      ['title_short']: string;
    };
    speed: number;
  };
};

export default componentFactory
  .mixin(injectStylesMixin(styles))
  .mixin(tsStoreMixin)
  .create({
    name: 'WidgetsBar',
    computed: {
      currencyWidgetData(): CurrencyBlockData | null {
        return this.store.commonModule.commonCurrencyBlock?.data || null;
      },
      trafficWidgetData(): TrafficBlockData | null {
        return this.store.commonModule.commonTrafficBlock?.data || null;
      },
      weatherWidgetData(): WeatherBlockData | null {
        return this.store.commonModule.commonWeatherBlock?.data || null;
      }
    },
    methods: {
      widgetClickHandler(name: string) {
        this.$emit('widgetClicked', name);
      }
    },
    render() {
      return (
        <div class={styles.widgetsBar}>
          {guardUnspecified(this.weatherWidgetData) && (
            <div class={styles.item}>
              <WidgetWeather
                widgetData={this.weatherWidgetData}
                class={[styles.widget, styles.weather]}
                onWeatherWidgetClicked={() => this.widgetClickHandler('Погода')}
              />
            </div>
          )}

          {guardUnspecified(this.trafficWidgetData) && (
            <div class={styles.item}>
              <WidgetTraffic
                widgetData={this.trafficWidgetData}
                class={styles.widget}
                onTrafficWidgetClicked={() => this.widgetClickHandler('Пробки')}
              />
            </div>
          )}

          {guardUnspecified(this.currencyWidgetData) && (
            <div class={styles.item}>
              <WidgetCurrency
                widgetData={this.currencyWidgetData}
                class={[styles.widget, styles.currency]}
                onCurrencyWidgetClicked={() => this.widgetClickHandler('Курс Валют')}
              />
            </div>
          )}
        </div>
      );
    }
  });
