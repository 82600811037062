import { isAbsoluteUrl } from '@portal/utils/util-url';
import { componentFactory } from 'vue-tsx-support';

import { JtnUiRecordStatisticsWillDeprecate } from '@jtn-ui/components/record';
import {
  JtnUiPrimaryRecordItem,
  JtnUiSecondaryRecordItem,
  JtnUiBaseBlockWillDeprecate
} from '@jtnews/jtn-ui';
import {
  injectStylesMixin,
  tsStoreMixin,
  RecordItemUtilsMixin,
  ObserverVisibility,
  JtnResponsiveImage,
  changeImagePlaceholder
} from '@jtnews/shared';
import { addUrlParams } from '@jtnews/shared/news';

import styles from './widget-immortal-rotation.styles.scss?module';

// eslint-disable-next-line @typescript-eslint/naming-convention
const BlockedUserModal = () =>
  import(/* webpackChunkName: "blocked-user-modal" */ '@jtnews/layout/modals').then(
    m => m.BlockedUserModal
  );

type ImmortalNews = {
  commentsCount: number;
  header: string;
  id: number;
  isCommentsAllowed: boolean;
  mainPhoto: {
    author: string;
    description: string;
    fileMask: string;
    height: number;
    id: string;
    sourceName: string;
    url: string;
    width: number;
  };
  urls: {
    url: string;
    urlComments: string;
  };
  viewsCount: number;
};

type NewFormatReachGoal = {
  goalName: string;
  actionType: string;
  prop1: string;
  prop2?: string;
};

type ReachGoalValues = string | { [key: string]: string };

type BlockDataParams = {
  title: string;
};

type ReachGoalFromApiType = {
  [key: string]: string;
};

export default componentFactory
  .mixin(injectStylesMixin(styles))
  .mixin(tsStoreMixin)
  .mixin(RecordItemUtilsMixin)
  .create({
    name: 'WidgetImmortalRotation',
    data() {
      return {
        lazyloadImmortalObsVisibility: new ObserverVisibility('lazyLoad'),
        halfImmortalObsVisibility: new ObserverVisibility('half'),
        blockData: {
          params: {
            title: ''
          },
          data: [],
          // eslint-disable-next-line
          reach_goal: {
            general: []
          }
        },
        isBlockedUserModalOpened: false,
        isBlockedUserModalRendered: false
      };
    },
    computed: {
      newsApiClient() {
        return this.store.newsApiClient;
      },
      regionId(): number {
        return this.store.regionId;
      },
      items(): ImmortalNews[] {
        return this.blockData?.data || [];
      },
      params(): BlockDataParams {
        return this.blockData?.params || null;
      },
      reachGoalsFromApi(): ReachGoalFromApiType[] {
        return this.blockData.reach_goal?.general || [];
      },
      isBlockedUser(): boolean {
        return this.store.commonModule?.isBlockedUser;
      }
    },
    mounted() {
      this.lazyloadImmortalObsVisibility.event.subscribe((state: { value: boolean }) => {
        if (state.value) {
          this.fetchBlockData() as unknown as void;
        }
      });
      this.halfImmortalObsVisibility.event.subscribe((state: { value: boolean }) => {
        if (state.value) {
          this.sendNewReachGoal('Просмотр', 'viewImperishableBlock');
          this.sendNewFormatReachGoal({
            goalName: 'ViewNetlen',
            actionType: 'Просмотр',
            prop1: this.items.map(item => item.id).join(', ')
          });
        }
      });
    },
    beforeDestroy() {
      this.lazyloadImmortalObsVisibility.destroy();
      this.halfImmortalObsVisibility.destroy();
    },
    methods: {
      async fetchBlockData() {
        try {
          const res = await this.newsApiClient.getEverlastingNewsBlock({
            regionId: this.regionId
          });
          this.blockData = res.data;

          this.sendReachGoalFromApi(this.reachGoalsFromApi);
        } catch (err) {
          console.error(err);
          throw err;
        }
      },
      changeImagePlaceholder(image: ImmortalNews['mainPhoto']) {
        return changeImagePlaceholder(image);
      },
      getDiscussUrl(url: string) {
        return addUrlParams(url, { discuss: '1' });
      },
      clickImage(id: number) {
        this.sendNewReachGoal(
          // eslint-disable-next-line @typescript-eslint/naming-convention
          { 'Переход на материал (фото)': id.toString() },
          'clickImperishableBlock'
        );
        this.sendNewFormatReachGoal({
          goalName: 'ClickNetlen',
          actionType: 'Клик',
          prop1: id.toString(),
          prop2: 'Картинка'
        });
      },
      clickedDiscussLink(valueName: string, valueNameNewFormat: string, id: number) {
        if (this.isBlockedUser) {
          this.openBlockedUserModal();
        } else {
          this.sendReachGoalForClickLink(valueName, valueNameNewFormat, id);
        }
      },
      sendReachGoalForClickLink(
        valueName: string,
        valueNameNewFormat: string,
        id: number
      ) {
        this.sendNewReachGoal({ valueName: id.toString() }, 'clickImperishableBlock');
        this.sendNewFormatReachGoal({
          goalName: 'ClickNetlen',
          actionType: 'Клик',
          prop1: id.toString(),
          prop2: valueNameNewFormat
        });
      },
      getTargetLink(path: string): '_blank' | '_self' {
        return isAbsoluteUrl(path) ? '_blank' : '_self';
      },
      sendNewReachGoal(valueName: ReachGoalValues, goalName: string) {
        const blockType = 'Центральная колонка';
        const fieldType = 'Нетленка (блок)';

        this.store.analyticsModule.sendNewReachGoal({
          blockType,
          fieldType,
          valueName,
          goalName,
          willDeprecate: true
        });
      },
      sendNewFormatReachGoal({ goalName, actionType, prop1, prop2 }: NewFormatReachGoal) {
        this.store.analyticsModule.sendNewFormatReachGoal({
          blockType: 'Нетленка',
          goalName,
          actionType,
          prop1,
          prop2
        });
      },
      sendReachGoalFromApi(goalNames: ReachGoalFromApiType[]) {
        if (goalNames?.length > 0) {
          goalNames.forEach(name => {
            this.store.analyticsModule.sendReachGoal({ name });
          });
        }
      },
      openBlockedUserModal() {
        this.isBlockedUserModalOpened = true;
        this.isBlockedUserModalRendered = true;
      }
    },
    render() {
      return (
        <div
          v-observe-visibility={this.lazyloadImmortalObsVisibility.getOptions('lazyLoad')}
          class={styles.widgetImmortal}
        >
          {this.items?.length > 0 && this.params.title ? (
            <JtnUiBaseBlockWillDeprecate
              v-observe-visibility={this.halfImmortalObsVisibility.getOptions('half')}
              header={this.params.title}
            >
              <div slot="content" class={styles['content']}>
                {this.items.map((item: ImmortalNews, index: number) =>
                  index === 0 ? (
                    <JtnUiPrimaryRecordItem
                      class={styles.primaryRecord}
                      key={`${index}${item.id}`}
                      header={item.header}
                      url={item.urls.url}
                      targetUrl={this.getTargetLink(item.urls.url)}
                      place="right"
                      onRecordLinkClicked={() =>
                        this.sendReachGoalForClickLink(
                          'Переход на материал (заголовок)',
                          'Заголовок',
                          item.id
                        )
                      }
                      onRecordImageClicked={() => this.clickImage(item.id)}
                    >
                      <JtnResponsiveImage
                        slot="image"
                        image-url={this.changeImagePlaceholder(item.mainPhoto)}
                        image-ratio={16 / 9}
                        max-width={1200}
                        width-on-screen={25}
                        width-on-screen-tablet={200}
                        width-on-screen-smartphone={200}
                        alt={item.header}
                        class="responsive-image"
                      />
                      <JtnUiRecordStatisticsWillDeprecate
                        slot="stats"
                        views={item.viewsCount}
                        comments={item.commentsCount}
                        commentsUrl={item.urls.urlComments}
                        commentsEnabled={item.isCommentsAllowed}
                        discussText={this.$_RecordItemUtilsMixin_discussText}
                        discussUrl={this.getDiscussUrl(item.urls.urlComments)}
                        targetUrl={this.getTargetLink(item.urls.urlComments)}
                        class={styles[item.commentsCount > 0 ? '' : 'stats']}
                        onDiscuss={() =>
                          this.clickedDiscussLink(
                            'Переход на комментарии',
                            'Комментарии',
                            item.id
                          )
                        }
                        onCommentsClicked={() =>
                          this.sendReachGoalForClickLink(
                            'Переход на комментарии',
                            'Комментарии',
                            item.id
                          )
                        }
                      />
                    </JtnUiPrimaryRecordItem>
                  ) : (
                    <JtnUiSecondaryRecordItem
                      class={styles.secondaryRecord}
                      key={`${index}${item.id}`}
                      url={item.urls.url}
                      target={this.getTargetLink(item.urls.url)}
                      header={item.header}
                      recordType="right"
                      onLinkClicked={() =>
                        this.sendReachGoalForClickLink(
                          'Переход на материал (заголовок)',
                          'Заголовок',
                          item.id
                        )
                      }
                    >
                      <JtnUiRecordStatisticsWillDeprecate
                        slot="stats"
                        views={item.viewsCount}
                        comments={item.commentsCount}
                        commentsUrl={item.urls.urlComments}
                        commentsEnabled={item.isCommentsAllowed}
                        discussText={this.$_RecordItemUtilsMixin_discussText}
                        discussUrl={this.getDiscussUrl(item.urls.urlComments)}
                        targetUrl={this.getTargetLink(item.urls.urlComments)}
                        class={styles[item.commentsCount > 0 ? '' : 'stats']}
                        onDiscuss={() =>
                          this.clickedDiscussLink(
                            'Переход на комментарии',
                            'Комментарии',
                            item.id
                          )
                        }
                        onCommentsClicked={() =>
                          this.sendReachGoalForClickLink(
                            'Переход на комментарии',
                            'Комментарии',
                            item.id
                          )
                        }
                      />
                    </JtnUiSecondaryRecordItem>
                  )
                )}
              </div>
            </JtnUiBaseBlockWillDeprecate>
          ) : null}
          {this.isBlockedUserModalRendered && (
            <BlockedUserModal vModel={this.isBlockedUserModalOpened} />
          )}
        </div>
      );
    }
  });
