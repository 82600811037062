import { guardUnspecified } from '@portal/utils/util-guards';

type Options<Result> = {
  isImmediate?: boolean;
  maxWait?: number;
  callback?: (data: Result) => void;
};

type DebouncedFunction<F extends (...args: Parameters<F>) => ReturnType<F>> = {
  (this: ThisParameterType<F>, ...args: Parameters<F>): Promise<ReturnType<F>>;
  cancel: (reason?: unknown) => void;
};

type DebouncedPromise<FunctionReturn> = {
  resolve: (result: FunctionReturn) => void;
  reject: (reason?: unknown) => void;
};

export function debounce<F extends (...args: Parameters<F>) => ReturnType<F>>(
  func: F,
  waitMilliseconds = 50,
  options: Options<ReturnType<F>> = {}
): DebouncedFunction<F> {
  let timeoutId: ReturnType<typeof setTimeout> | undefined;
  const { isImmediate, maxWait, callback } = options;
  let lastInvokeTime = Date.now();

  let promises: DebouncedPromise<ReturnType<F>>[] = [];

  function nextInvokeTimeout() {
    if (guardUnspecified(maxWait)) {
      const timeSinceLastInvocation = Date.now() - lastInvokeTime;

      if (timeSinceLastInvocation + waitMilliseconds >= maxWait) {
        return maxWait - timeSinceLastInvocation;
      }
    }

    return waitMilliseconds;
  }

  const debouncedFunction = function (
    this: ThisParameterType<F>,
    ...args: Parameters<F>
  ) {
    return new Promise<ReturnType<F>>((resolve, reject) => {
      const invokeFunction = () => {
        timeoutId = undefined;
        lastInvokeTime = Date.now();
        if (!isImmediate) {
          const result = func.apply(this, args);
          guardUnspecified(callback) && callback(result as ReturnType<F>);
          promises.forEach(({ resolve }) => resolve(result as ReturnType<F>));
          promises = [];
        }
      };

      const isShouldCallNow = isImmediate && !guardUnspecified(timeoutId);

      if (guardUnspecified(timeoutId)) {
        clearTimeout(timeoutId);
      }

      timeoutId = setTimeout(invokeFunction, nextInvokeTimeout());

      if (isShouldCallNow) {
        const result = func.apply(this, args);
        guardUnspecified(callback) && callback(result as ReturnType<F>);
        return resolve(result as ReturnType<F>);
      }
      promises.push({ resolve, reject });
    });
  };

  debouncedFunction.cancel = function (reason?: unknown) {
    if (guardUnspecified(timeoutId)) {
      clearTimeout(timeoutId);
    }
    promises.forEach(({ reject }) => reject(reason));
    promises = [];
  };

  return debouncedFunction;
}
