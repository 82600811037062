import CloudyRainSvg from 'jtn-ui/svg/weather/cloudy-rain.svg';
import CloudySnowSvg from 'jtn-ui/svg/weather/cloudy-snow.svg';
import CloudyStormSvg from 'jtn-ui/svg/weather/cloudy-storm.svg';
import CloudySvg from 'jtn-ui/svg/weather/cloudy.svg';
import MoonCloudRainSvg from 'jtn-ui/svg/weather/moon-cloud-rain.svg';
import MoonCloudSnowSvg from 'jtn-ui/svg/weather/moon-cloud-snow.svg';
import MoonCloudStormSvg from 'jtn-ui/svg/weather/moon-cloud-storm.svg';
import MoonCloudSvg from 'jtn-ui/svg/weather/moon-cloud.svg';
import MoonSvg from 'jtn-ui/svg/weather/moon.svg';
import MostlyCloudyRainSvg from 'jtn-ui/svg/weather/mostly-cloudy-rain.svg';
import MostlyCloudySnowSvg from 'jtn-ui/svg/weather/mostly-cloudy-snow.svg';
import MostlyCloudyStormSvg from 'jtn-ui/svg/weather/mostly-cloudy-storm.svg';
import MostlyCloudySvg from 'jtn-ui/svg/weather/mostly-cloudy.svg';
import SunCloudRainSvg from 'jtn-ui/svg/weather/sun-cloud-rain.svg';
import SunCloudSnowSvg from 'jtn-ui/svg/weather/sun-cloud-snow.svg';
import SunCloudStormSvg from 'jtn-ui/svg/weather/sun-cloud-storm.svg';
import SunCloudSvg from 'jtn-ui/svg/weather/sun-cloud.svg';
import SunSvg from 'jtn-ui/svg/weather/sun.svg';
import { componentFactoryOf } from 'vue-tsx-support';

import { injectStylesMixin } from '@jtnews/shared';

import styles from './widget-weather.styles.scss?module';

const WEATHER_SVG_MAP = {
  ['cloudy']: CloudySvg,
  ['cloudy-rain']: CloudyRainSvg,
  ['cloudy-snow']: CloudySnowSvg,
  ['cloudy-storm']: CloudyStormSvg,
  ['mostly-cloudy']: MostlyCloudySvg,
  ['mostly-cloudy-rain']: MostlyCloudyRainSvg,
  ['mostly-cloudy-snow']: MostlyCloudySnowSvg,
  ['mostly-cloudy-storm']: MostlyCloudyStormSvg,
  ['sun']: SunSvg,
  ['sun-cloud']: SunCloudSvg,
  ['sun-cloud-rain']: SunCloudRainSvg,
  ['sun-cloud-snow']: SunCloudSnowSvg,
  ['sun-cloud-storm']: SunCloudStormSvg,
  ['moon']: MoonSvg,
  ['moon-cloud']: MoonCloudSvg,
  ['moon-cloud-rain']: MoonCloudRainSvg,
  ['moon-cloud-snow']: MoonCloudSnowSvg,
  ['moon-cloud-storm']: MoonCloudStormSvg
};

type WeatherIconName =
  | 'cloudy'
  | 'cloudy-rain'
  | 'cloudy-snow'
  | 'cloudy-storm'
  | 'mostly-cloudy'
  | 'mostly-cloudy-rain'
  | 'mostly-cloudy-snow'
  | 'mostly-cloudy-storm'
  | 'sun'
  | 'sun-cloud'
  | 'sun-cloud-rain'
  | 'sun-cloud-snow'
  | 'sun-cloud-storm'
  | 'moon'
  | 'moon-cloud'
  | 'moon-cloud-rain'
  | 'moon-cloud-snow'
  | 'moon-cloud-storm';

type WeatherType =
  | 'cloudy_none_day'
  | 'cloudy_none_night'
  | 'cloudy_light_rain_day'
  | 'cloudy_light_rain_night'
  | 'cloudy_rain_day'
  | 'cloudy_rain_night'
  | 'cloudy_light_snow_day'
  | 'cloudy_light_snow_night'
  | 'cloudy_snow_day'
  | 'cloudy_snow_night'
  | 'cloudy_thunderstorm_day'
  | 'cloudy_thunderstorm_night'
  | 'cloudy_rainless_day'
  | 'mostly_cloudy_none_day'
  | 'mostly_cloudy_none_night'
  | 'mostly_cloudy_light_rain_day'
  | 'mostly_cloudy_light_rain_night'
  | 'mostly_cloudy_rain_day'
  | 'mostly_cloudy_rain_night'
  | 'mostly_cloudy_light_snow_day'
  | 'mostly_cloudy_light_snow_night'
  | 'mostly_cloudy_snow_day'
  | 'mostly_cloudy_snow_night'
  | 'mostly_cloudy_thunderstorm_day'
  | 'mostly_cloudy_thunderstorm_night'
  | 'sunshine_none_day'
  | 'partly_cloudy_none_day'
  | 'partly_cloudy_light_rain_day'
  | 'sunshine_rain_day'
  | 'sunshine_light_rain_day'
  | 'partly_cloudy_rain_day'
  | 'partly_cloudy_light_snow_day'
  | 'partly_cloudy_snow_day'
  | 'sunshine_light_snow_day'
  | 'sunshine_snow_day'
  | 'partly_cloudy_thunderstorm_day'
  | 'sunshine_none_night'
  | 'partly_cloudy_none_night'
  | 'partly_cloudy_light_rain_night'
  | 'partly_cloudy_rain_night'
  | 'sunshine_light_rain_night'
  | 'sunshine_rain_night'
  | 'partly_cloudy_light_snow_night'
  | 'partly_cloudy_snow_night'
  | 'sunshine_light_snow_night'
  | 'sunshine_snow_night'
  | 'partly_cloudy_thunderstorm_night';

type Weather = {
  icon: string;
  more: string;
  temperature: number;
};

type ComponentData = {
  weatherTypeMap: Record<WeatherType, WeatherIconName>;
};

type Events = {
  onWeatherWidgetClicked: void;
};

export default componentFactoryOf<Events>()
  .mixin(injectStylesMixin(styles))
  .create({
    name: 'WidgetWeatherRedesign',
    props: {
      widgetData: {
        type: Object as () => Weather,
        default: null
      }
    },
    data(): ComponentData {
      return {
        weatherTypeMap: {
          /* eslint-disable @typescript-eslint/naming-convention */
          cloudy_none_day: 'cloudy',
          cloudy_none_night: 'cloudy',
          cloudy_light_rain_day: 'cloudy-rain',
          cloudy_light_rain_night: 'cloudy-rain',
          cloudy_rain_day: 'cloudy-rain',
          cloudy_rain_night: 'cloudy-rain',
          cloudy_light_snow_day: 'cloudy-snow',
          cloudy_light_snow_night: 'cloudy-snow',
          cloudy_snow_day: 'cloudy-snow',
          cloudy_snow_night: 'cloudy-snow',
          cloudy_thunderstorm_day: 'cloudy-storm',
          cloudy_thunderstorm_night: 'cloudy-storm',
          cloudy_rainless_day: 'sun-cloud',
          mostly_cloudy_none_day: 'mostly-cloudy',
          mostly_cloudy_none_night: 'mostly-cloudy',
          mostly_cloudy_light_rain_day: 'mostly-cloudy-rain',
          mostly_cloudy_light_rain_night: 'mostly-cloudy-rain',
          mostly_cloudy_rain_day: 'mostly-cloudy-rain',
          mostly_cloudy_rain_night: 'mostly-cloudy-rain',
          mostly_cloudy_light_snow_day: 'mostly-cloudy-snow',
          mostly_cloudy_light_snow_night: 'mostly-cloudy-snow',
          mostly_cloudy_snow_day: 'mostly-cloudy-snow',
          mostly_cloudy_snow_night: 'mostly-cloudy-snow',
          mostly_cloudy_thunderstorm_day: 'mostly-cloudy-storm',
          mostly_cloudy_thunderstorm_night: 'mostly-cloudy-storm',
          sunshine_none_day: 'sun',
          partly_cloudy_none_day: 'sun-cloud',
          partly_cloudy_light_rain_day: 'sun-cloud-rain',
          sunshine_rain_day: 'sun-cloud-rain',
          sunshine_light_rain_day: 'sun-cloud-rain',
          partly_cloudy_rain_day: 'sun-cloud-rain',
          partly_cloudy_light_snow_day: 'sun-cloud-snow',
          partly_cloudy_snow_day: 'sun-cloud-snow',
          sunshine_light_snow_day: 'sun-cloud-snow',
          sunshine_snow_day: 'sun-cloud-snow',
          partly_cloudy_thunderstorm_day: 'sun-cloud-storm',
          sunshine_none_night: 'moon',
          partly_cloudy_none_night: 'moon-cloud',
          partly_cloudy_light_rain_night: 'moon-cloud-rain',
          partly_cloudy_rain_night: 'moon-cloud-rain',
          sunshine_light_rain_night: 'moon-cloud-rain',
          sunshine_rain_night: 'moon-cloud-rain',
          partly_cloudy_light_snow_night: 'moon-cloud-snow',
          partly_cloudy_snow_night: 'moon-cloud-snow',
          sunshine_light_snow_night: 'moon-cloud-snow',
          sunshine_snow_night: 'moon-cloud-snow',
          partly_cloudy_thunderstorm_night: 'moon-cloud-storm'
          /* eslint-enable @typescript-eslint/naming-convention */
        }
      };
    },
    computed: {
      weatherIcon(): string {
        const iconName = this.weatherTypeMap[this.widgetData.icon as WeatherType];
        return WEATHER_SVG_MAP[iconName];
      },
      temperature() {
        const temp = this.widgetData.temperature || 0;
        return temp >= 0 ? `+${temp}°` : `${temp}°`;
      }
    },
    methods: {
      widgetClickHandler() {
        this.$emit('weatherWidgetClicked');
      }
    },
    render() {
      return (
        <a
          class={styles.widgetWeather}
          href={this.widgetData.more}
          target="_blank"
          data-test="widget-traffic"
          onClick={() => this.widgetClickHandler()}
        >
          <img class={styles.icon} src={this.weatherIcon} alt="" />
          <p class={styles.temperature}>{this.temperature}</p>
        </a>
      );
    }
  });
